export const Button = (props)=>{
    const handleClick = props.onClick
    return(
        <button className="m-1 hover:text-brightRed" onClick={handleClick}> {props.text}</button>
    )
}
export const ActionButton = (props)=>{
  const className = props.className
  const handleClick = props.onClick
  return (
  <button className={className} onClick={handleClick} >{props.text}</button>
    )
}
export const handleLogOut = (props) => {
    localStorage.removeItem("user");
    localStorage.removeItem("uid");
    localStorage.removeItem("gameId");
    localStorage.removeItem("groupNum");
    localStorage.removeItem("token");
    fetch("https://www.simulator.ge.com/logout", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        props.token();
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
          console.log(error.response.status);
          console.log(error.response.headers);
        }
      });
      window.location.reload(false)

  };