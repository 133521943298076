import React, { useState, useEffect } from "react";
async function getEmpSatisfaction(uid, gameId, token) {
  const userData = {
    user_id: uid,
    game_id: gameId,
  };
  const response = await fetch(`https://www.simulator.ge/emp_satisfaction`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(userData),
  }).then((res) => res.json());
  return response;
}

async function getProductivity(uid, gameId, token) {
  const userData = {
    user_id: uid,
    game_id: gameId,
  };
  const response = await fetch(`https://www.simulator.ge/productivity`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(userData),
  }).then((res) => res.json());
  return response;
}

async function getCustomerSatisfaction(uid, gameId, token) {
  const userData = {
    user_id: uid,
    game_id: gameId,
  };
  const response = await fetch(
    `https://www.simulator.ge/customer_satisfaction`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(userData),
    }
  ).then((res) => res.json());
  return response;
}

const useChartData = (groupMembers) => {
  const token = localStorage.getItem("token");
  const [empSat, setEmpSat] = useState({});
  const [prodvi, setProdvi] = useState({});
  const [custSat, setCustSat] = useState({});

  useEffect(() => {
    groupMembers.forEach((member) => {
      getEmpSatisfaction(member[0], member[7], token).then((data) => {
        setEmpSat((prevState) => ({
          ...prevState,
          [member[1]]: data,
        }));
      });
      getCustomerSatisfaction(member[0], member[7], token).then((data) => {
        setCustSat((prevState) => ({
          ...prevState,
          [member[1]]: data,
        }));
      });
      getProductivity(member[0], member[7], token).then((data) => {
        setProdvi((prevState) => ({
          ...prevState,
          [member[1]]: data,
        }));
      });
    });
  }, [groupMembers]);

  return { empSat, prodvi, custSat };
};

const useGroupMembers = () => {
  const token = localStorage.getItem("token");
  const gameId = localStorage.getItem("gameId");
  const [groupMembers, setGroupMembers] = useState([]);
  useEffect(() => {
    fetch(`https://www.simulator.ge/all_users`, {
      method: "Get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        const result = data.filter((user) => user[7] === gameId);
        setGroupMembers(result);
      });
  }, []);

  return { groupMembers };
};
const AllRank = () => {
  const { groupMembers } = useGroupMembers();
  const { empSat, prodvi, custSat } = useChartData(groupMembers);
  const [playerStats, setPlayerStats] = useState([]);
  const groupId = localStorage.getItem("gameId");
  const [groupBudgets, setGroupBudgets] = useState([]);
  const token = localStorage.getItem("token");
  useEffect(() => {
    fetch(`https://www.simulator.ge/budget`, {
      method: "Get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        let tmp = [];
        data.forEach((user) => {
          if (user[3] == groupId) {
            tmp.push(user);
          }
        });
        tmp.sort(function(a,b){
          return b[2]-a[2]
        })
        setGroupBudgets(tmp);
      });
  }, [groupMembers]);
 

  useEffect(() => {
    let playerPoint = {};
    if (
      Object.keys(empSat).length >= groupMembers.length &&
      Object.keys(custSat).length >= groupMembers.length &&
      Object.keys(prodvi).length >= groupMembers.length
    ) {
      groupMembers.forEach((member) => {
        let memberName = member[1];
        let salary_points = empSat[memberName][0].salary_points;
        let bonus_points = empSat[memberName][0].bonus_points;
        let training_points = prodvi[memberName][0].training_points;
        let equipment_points = prodvi[memberName][0].equipment_points;
        let quality_points = custSat[memberName][0].quality_points;
        let emps_points = custSat[memberName][0].emps_points;
        let price_points = custSat[memberName][0].price_points;
        let points =
          salary_points +
          bonus_points +
          training_points +
          equipment_points +
          quality_points +
          emps_points +
          price_points;
        playerPoint[memberName] = points;
      });
    }
    let sortable = [];
for (let user in playerPoint) {
    sortable.push([user, playerPoint[user]]);
}

sortable.sort(function(a, b) {
    return b[1] - a[1];
});
    setPlayerStats(sortable);
  }, [empSat, prodvi, custSat]);

  return (
    <div className="all-points-wrapper">
      <table className="table">
        <thead>
          <tr>
            <th>ადგილი</th>
            <th>მონაწილის სახელი</th>
            <th>ქულა</th>
          </tr>
        </thead>
        <tbody>
          {playerStats &&
            playerStats.map((member, i) => (
              <tr key={member[0]}>
                <td>{i + 1} </td>
                <td>{member[0]} </td>
                <td>{member[1]} </td>
              </tr>
            ))}
        </tbody>
      </table>
      <table className="table">
        <thead>
          <tr>
            <th>ადგილი</th>
            <th>მონაწილის სახელი</th>
            <th>თანხა</th>
          </tr>
        </thead>
        <tbody>
          {groupBudgets.length > 0 &&
            groupBudgets.map((member, i) => (
              <tr key={member[0]}>
                <td>{i + 1}</td>
                <td> {member[1]}</td>
                <td> {member[2]}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};
export default AllRank;
