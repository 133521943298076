import {Link} from 'react-router-dom'
import { Button } from "../../tools/tools"

const UserMenu = (props)=>{
    const lang = props.lang
    const language = {
        en:{
            simulation:'Start Simulation',
            rank:"Ratings"
        },
        ge:{
            simulation:'სიმულაციის დაწყება',
            rank:"რეიტინგი"
        }
    }
    return (
        <div className="h-4/5 flex flex-col items-start">
            <Link to='profile/user/game/gameflow'> 
            <Button text={language[lang].simulation}/>
            </Link>
            <Link to='profile/user/game/ratings'>
            <Button text={language[lang].rank}/>
            </Link>
        </div>
    )
}
export default UserMenu