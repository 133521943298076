const Notifications = () => {
  return (
    <div className="notifications-wrapper">
      შეტყობინებები :
      <div className="notications-table">
        <table className="table">
          <thead className="thead-dark">
            <tr>
              <th scope="col">#</th>
              <th scope="col">გამომგზავნი</th>
              <th scope="col">თემა</th>
              <th scope="col">მესიჯი</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">1</th>
              <td>Mark</td>
              <td>სატესტო</td>
              <td>ტესტ ტესტ</td>
            </tr>
            <tr>
              <th scope="row">2</th>
              <td>Jacob</td>
              <td>Thornton</td>
              <td>@fat</td>
            </tr>
            <tr>
              <th scope="row">3</th>
              <td>Larry</td>
              <td>the Bird</td>
              <td>@twitter</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Notifications;
