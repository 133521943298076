import React, { useState, useEffect } from "react";
import LoadingSpinner from "../components/LoadingSpinner";
import Task1Lvl3 from "./level3/Task1Lvl3";
import { MyProduct, Product, ProductCard } from "./tools/tools";
const Productebi = () => {
  const [activeProds, setActiveProds] = useState([]);
  const [show, setShow] = useState(true);
  const token = localStorage.getItem("token");
  const uid = localStorage.getItem("uid");
  const [level, setLevel] = useState("0");
  useEffect(() => {
    fetch(`https://www.simulator.ge/all_users`, {
      method: "Get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        data.forEach((item) => {
          if (item[0] == uid) {
            setLevel(item[5]);
          }
        });
      });
  }, []);
  useEffect(() => {
    fetch(`https://www.simulator.ge/gayidvebis_baza`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        let tmp = [];
        data.forEach((element) => {
          if (element[1] == uid) {
            tmp.push(element);
          }
        });
        setActiveProds(tmp);
        setShow(false);
      });
  }, []);
  return (
    <div className="my_products_wrapper">
      {show && <LoadingSpinner />}
      <h2>შექმნილი პროდუქტები</h2>
      {/* {activeProds
        ? activeProds.map((item) => <MyProduct name={item[3]} qtt={item[2]} price={item[1]} />)
        : null} */}
      {activeProds && <MyProduct arr={activeProds} />}
      {/* <div>
        <h2>შექმენი პროდუქტი</h2>
        <Task1Lvl3 level={level} />
      </div> */}
    </div>
  );
};
export default Productebi;
