import React,{useState} from 'react';
import Menu from '../Modal/Modal'

const Header = (props) => {
    const [showMenu,setShowMenu] = useState(false)
    const btn = document.getElementById("menu-btn");
    const lang =props.lang
    const setLang = props.setLang
  const handleClick = () => {
    setShowMenu(!showMenu)
    btn.classList.toggle("open");
  };
  return (
    <>
    <div className="w-full h-10vh bg-darkBlue flex fixed justify-center align-center p-6 text-white drop-shadow-md m-0 p-0 top-0">
      <div className="w-1/2 text-center h-10vh flex ml-6 items-center text-3xl">BSG</div>
      <div className="w-1/2 text-center flex justify-end mr-6 items-end h-10vh">
        <button className='p-2' onClick={()=>setLang('en')}>En</button>
        <button className='p-2' onClick={()=>setLang('ge')}>Ge</button>
        <button
          id="menu-btn"
          className="block hamburger focus:outline-none drop-shadow-md mb-1"
          onClick={handleClick}
        >
          <span className="hamburger-top"></span>
          <span className="hamburger-middle"></span>
          <span className="hamburger-bottom"></span>
        </button>
      </div>
    </div>
    <div className='h-10vh'></div>
    {showMenu&& <Menu user={props.user} lang={lang}/>}
    
    </>
  );
};
export default Header;
