import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import LoadingSpinner from "../../components/LoadingSpinner";
import { changeLevel, updateBudget } from "../tools/tools";
import "./tasks.css";
const Task2 = (props) => {
  const token = localStorage.getItem("token");
  const uid = localStorage.getItem("uid");
  const gameId = localStorage.getItem("gameId");
  const [activeEmps, setActiveEmps] = useState([]);
  const [addEmp, setAddEmp] = useState("");
  const [showSelectedOption, setShowSelectedOption] = useState([]);
  const [emps, setEmps] = useState({});
  const [employeeTitles, setEmployeeTitles] = useState([]);
  const [show, setShow] = useState(true);
  const userName = localStorage.getItem('user').slice(1,-1)
  setTimeout(() => {
    setShow(false);
  }, 300);
  useEffect(() => {
    fetch("https://www.simulator.ge/active_emps", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        let tmp = [];
        data.map((item) => {
          if (item[2] == uid) {
            tmp.push(+item[1]);
          }
        });
        setActiveEmps(tmp);
      });
  }, [addEmp]);
  useEffect(() => {
    fetch("https://www.simulator.ge/emps", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setEmps(data);
        let empTitles = [];
        data.forEach((element) => {
          if (element.length > 0 && empTitles.indexOf(element[1]) < 0) {
            empTitles.push(element[1]);
          }
        });
        setEmployeeTitles(empTitles);
      });
  }, [token]);
  // localStorage.setItem("user", JSON.stringify(userName));
  // toast('ადმინის პანელი')
  const [selectedCategory, setSelectedCategory] = useState("");
  const showOption = (e) => {
    setShow(true);
    setSelectedCategory(e.target.value);
  };
  useEffect(() => {
    if (selectedCategory) {
      let tmp = [];
      if (emps.length > 0) {
        emps.forEach((item) => {
          if (item[1] === selectedCategory) {
            tmp.push(item);
          }
        });
      }
      setShowSelectedOption(tmp);
    }
  }, [selectedCategory]);
  const hireEmployee = (id,salary) => {
    salary=parseInt(salary)
    setShow(true);
    let token = localStorage.getItem("token");
    let uid = localStorage.getItem("uid");
    let data = {
      user_id: uid,
      emps_id: id,
      game_id: gameId,
    };
    fetch("https://www.simulator.ge/add_active_emps", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        setActiveEmps([...activeEmps, id]);
        setTimeout(() => {
          setShow(false);
        }, 300);
        toast("თანამშრომელი დაქირავებულია");
        updateBudget(gameId,userName, -salary)
      });

  };
  const saveInfo = () => {
    toast('რაუნდი დასრულებულია')
    changeLevel(props.level);
  };
  
  return (
    <div className="">
      {show && <LoadingSpinner />}
      <div className="">
        <div className="">
          <select onChange={showOption} className="bg-buttonColor rounded-xl p-1 hover:cursor-pointer">
            <option className="hover:cursor-pointer">აირჩიეთ კატეგორია</option>
            {employeeTitles.length > 1
              ? employeeTitles.map((item) => <option key={item}>{item}</option>)
              : null}
          </select>
        </div>

        <div className="employee-card-wrapper">
          {showSelectedOption.length > 1
            ? showSelectedOption.map((item, index) => {
                let arr = ["1", "2", 3, 4, 5];
                if (activeEmps.includes(item[0])) {
                } else {
                  return (
                    <div className="w-full max-w-sm bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700 m-4 text-black"            value={item.name}
                     key={item[0]}>
                      <div className="flex justify-end px-4 pt-4">
 
                        <div
                          id="dropdown"
                          className="hidden z-10 w-44 text-base list-none bg-white rounded divide-y divide-gray-100 shadow dark:bg-gray-700"
                        >
                          <ul className="py-1" aria-labelledby="dropdownButton">
                            <li>
                              <a
                                href="#"
                                className="block py-2 px-4 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
                              >
                                Edit
                              </a>
                            </li>
                            <li>
                              <a
                                href="#"
                                className="block py-2 px-4 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
                              >
                                Export Data
                              </a>
                            </li>
                            <li>
                              <a
                                href="#"
                                className="block py-2 px-4 text-sm text-red-600 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
                              >
                                Delete
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="flex flex-col items-center pb-2">

                        <h5 className="mb-1 text-xl font-medium text-gray-900 dark:text-white">
                         {item[3]} {item[4]}
                        </h5>
                        <span className="text-sm text-gray-500 dark:text-gray-400">
                        {item[6]} წლის
                        </span>                      <span className="text-sm text-gray-500 dark:text-gray-400">
                        რანკი:  {item[7]} 
                        </span>                      <span className="text-sm text-gray-500 dark:text-gray-400">
                         ხელფასი {item[8]} 
                        </span>
                        <div className="flex mt-4 space-x-3 md:mt-6">
                          <a
                            href="#"
                            className="inline-flex items-center py-2 px-4 text-sm font-medium text-center text-white bg-dark rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                            onClick={() => hireEmployee(item[0],item[8])}
                            value={item.name}>
                            დაქირავება
                          </a>
                        </div>
                      </div>
                    </div>
                    // <div
                    //   className="employee-card"
                    //   value={item.name}
                    //   key={item[0]}
                    // >
                    //   <div className="employee-info">
                    //     <ul>
                    //       <li>
                    //         
                    //       </li>
                    //       <li>ასაკი: </li>
                    //       <li>სქესი: {item[5]}</li>
                    //       <li>გამოცდილება: {item[7]}</li>
                    //       <li>ხელფასი: {item[8]} </li>
                    //     </ul>
                    //   </div>

                    //   <div className="employee-hire">
                    //     <button
              
                    //       className="btn btn-success"
                    //     >
                    //       დაქირავება
                    //     </button>
                    //   </div>
                    // </div>
                  );
                }
              })
            : null}
        </div>
      </div>
      <div className="select_wrapper">
        <button className="btn btn-success finish_level" onClick={saveInfo}>
          რაუნდის დასრულება
        </button>
      </div>
    </div>
  );
};
export default Task2;
