import React, { useState, useEffect } from "react";
import Task1Lvl2 from "./level2/Task1Lvl2";
import "./styles.css";
import "bootstrap/dist/css/bootstrap.min.css";
import LoadingSpinner from "../components/LoadingSpinner";
const Maragebi = () => {
  const [stocks, setStocks] = useState([]);
  const [momts1, setMomts1] = useState([]);
  const [momts2, setMomts2] = useState([]);
  const [momts3, setMomts3] = useState([]);
  const [show, setShow] = useState(true);
  const token = localStorage.getItem("token");
  const uid = localStorage.getItem("uid");
  const [level, setLevel] = useState("0");
  useEffect(() => {
    fetch(`https://www.simulator.ge/all_users`, {
      method: "Get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        data.forEach((item) => {
          if (item[0] == uid) {
            setLevel(item[5]);
          }
        });
      });
  }, []);
  useEffect(() => {
    fetch(`https://www.simulator.ge/yidvis_baza`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        let tmp = data.filter((product) => product[1] == uid);
        setStocks(tmp);
        setTimeout(() => {
          setShow(false);
        }, 300);
      });
  }, []);
  useEffect(() => {
    fetch(`https://www.simulator.ge/momts1`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setMomts1(data);
      });
  }, []);
  useEffect(() => {
    fetch(`https://www.simulator.ge/momts2`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setMomts2(data);
      });
  }, []);
  useEffect(() => {
    fetch(`https://www.simulator.ge/momts3`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setMomts3(data);
      });
  }, []);
  const getProdName = (id, momts) => {
    let name = "";
    if (momts == 1) {
      momts1.forEach((item) => {
        if (item[0] == id) {
          name = item[1];
        }
      });
    } else if (momts == 2) {
      momts2.forEach((item) => {
        if (item[0] == id) {
          name = item[1];
        }
      });
    } else if (momts == 3) {
      momts3.forEach((item) => {
        if (item[0] == id) {
          name = item[1];
        }
      });
    }
    return name;
  };
  return (
    <div className="maragebi_wrapper bg-white">
      {show && <LoadingSpinner />}
      <h2>არსებული მარაგები</h2>
      <table className="table">
        <thead>
          <tr>
            <th>აიდი</th>
            <th>დასახელება</th>
            <th>ხარისხი</th>
            <th>ფასი</th>
            <th>რაოდენობა</th>
          </tr>
        </thead>
        <tbody>
          {stocks
            ? stocks.map((item) => {
                if (item[1] == uid) {
                  return (
                    <tr key={item[0]}>
                      <td> {item[3]}</td>
                      <td>{getProdName(item[3], item[4])}</td>
                      <td>{item[4]}</td>
                      <td>{item[5]}</td>
                      <td> {item[6]}</td>
                    </tr>
                  );
                }
              })
            : null}
        </tbody>
      </table>
      {/* <h2> მარაგების შეძენა </h2>
      <Task1Lvl2 level={level} /> */}
    </div>
  );
};
export default Maragebi;
