import pizaImg from "../resources/images/products/pizza.jpg";
import khachapuri from "../resources/images/products/khachapuri.jpg";
import lobiani from "../resources/images/products/lobiani.jpg";
import gvezeli from "../resources/images/products/gvezeli.jpg";
import cola from "../resources/images/products/cola.jpg";
import adjaruli from "../resources/images/products/adjaruli.jpg";
import brownie from "../resources/images/products/brownie.jpg";
import chicken from "../resources/images/products/chicken.jpg";
import coffee from "../resources/images/products/coffee.jpg";
import potato from "../resources/images/products/potato.jpg";
import beer from "../resources/images/products/beer.jpg";
import bread from "../resources/images/products/bread.jpg";
import cheese from "../resources/images/products/cheese.jpg";
import stake from "../resources/images/products/stake.jpg";
import mushroom from "../resources/images/products/mushroom.jpg";
import club from "../resources/images/products/club.jpg";
const AllproductsDb = [
  {
    productNameGe: "პიცა მარგარიტა",
    productNameEn: "piza",
    sourse: pizaImg,
    raund: 1,
    avgPrice: 22,
    ingredients: [
      {
        ingredientNameGe: "ფქვილი",
        ingredientNameEn: "Povder",
        ingredientQuantity: 0.5,
        ingredientAmount: "კგ",
        ingredientAmountEn: "KG",
        ingredientId: 1,
      },
      {
        ingredientNameGe: "ყველი მოცარელა",
        ingredientNameEn: "Cheese",
        ingredientQuantity: 0.3,
        ingredientAmount: "კგ",
        ingredientAmountEn: "KG",
        ingredientId: 3,
      },
      {
        ingredientNameGe: "პომიდვრის საწეებლი",
        ingredientNameEn: "Tomato Pasta",
        ingredientQuantity: 0.1,
        ingredientAmount: "ლ",
        ingredientAmountEn: "Litter",
        ingredientId: 2,
      },
    ],
  },
  {
    productNameGe: "იმერული ხაჭაპური",
    productNameEn: "Khachapuri",
    sourse: khachapuri,
    raund: 1,
    avgPrice: 20,
    ingredients: [
      {
        ingredientNameGe: "ფქვილი",
        ingredientNameEn: "Povder",
        ingredientQuantity: 0.5,
        ingredientAmount: "კგ",
        ingredientAmountEn: "KG",
        ingredientId: 1,
      },
      {
        ingredientNameGe: "ყველი იმერული",
        ingredientNameEn: "Cheese",
        ingredientQuantity: 0.5,
        ingredientAmount: "კგ",
        ingredientAmountEn: "KG",
        ingredientId: 4,
      },
    ],
  },
  {
    productNameGe: "ლობიანი",
    productNameEn: "Lobiani",
    sourse: lobiani,
    raund: 1,
    avgPrice: 16,
    ingredients: [
      {
        ingredientNameGe: "ფქვილი",
        ingredientNameEn: "Povder",
        ingredientQuantity: 0.5,
        ingredientAmount: "კგ",
        ingredientAmountEn: "KG",
        ingredientId: 1,
      },
      {
        ingredientNameGe: "ლობიო",
        ingredientNameEn: "Fasol",
        ingredientQuantity: 0.25,
        ingredientAmount: "კგ",
        ingredientAmountEn: "KG",
        ingredientId: 6,
      },
    ],
  },
  {
    productNameGe: "ღვეზელი",
    productNameEn: "Potato",
    sourse: gvezeli,
    raund: 1,
    avgPrice: 12,
    ingredients: [
      {
        ingredientNameGe: "ფქვილი",
        ingredientNameEn: "Povder",
        ingredientQuantity: 0.5,
        ingredientAmount: "კგ",
        ingredientAmountEn: "KG",
        ingredientId: 1,
      },
      {
        ingredientNameGe: "კარტოფილი",
        ingredientNameEn: "Potato",
        ingredientQuantity: 0.3,
        ingredientAmount: "კგ",
        ingredientAmountEn: "KG",
        ingredientId: 5,
      },
    ],
  },
  {
    productNameGe: "გაზიანი სასმელი",
    productNameEn: "Soda",
    sourse: cola,
    raund: 1,
    avgPrice: 6,
    ingredients: [
      {
        ingredientNameGe: "გაზიანი სასმელი",
        ingredientNameEn: "Soda",
        ingredientQuantity: 0.5,
        ingredientAmount: "ლ",
        ingredientAmountEn: "L",
        ingredientId: 15,
      },
    ],
  },
  {
    productNameGe: "ქათმის სალათი",
    productNameEn: "Chicken salade",
    sourse: chicken,
    raund: 2,
    avgPrice: 17,
    ingredients: [
      {
        ingredientNameGe: "ქათმის ფილე",
        ingredientNameEn: "Chicken",
        ingredientQuantity: 0.2,
        ingredientAmount: "კგ",
        ingredientAmountEn: "KG",
        ingredientId: 16,
      },
      {
        ingredientNameGe: "მაიონეზი",
        ingredientNameEn: "Maionezi",
        ingredientQuantity: 0.2,
        ingredientAmount: "კგ",
        ingredientAmountEn: "KG",
        ingredientId: 17,
      },
      {
        ingredientNameGe: "ბულგარული",
        ingredientNameEn: "bulgaruli",
        ingredientQuantity: 0.1,
        ingredientAmount: "კგ",
        ingredientAmountEn: "KG",
        ingredientId: 18,
      },
    ],
  },
  {
    productNameGe: "აჭარული ხაჭაპური",
    productNameEn: "Adjarian Khachapuri",
    sourse: adjaruli,
    raund: 2,
    avgPrice: 16,
    ingredients: [
      {
        ingredientNameGe: "ფქვილი",
        ingredientNameEn: "Povder",
        ingredientQuantity: 0.2,
        ingredientAmount: "კგ",
        ingredientAmountEn: "KG",
        ingredientId: 1,
      },
      {
        ingredientNameGe: "ყველი იმერული",
        ingredientNameEn: "Cheese",
        ingredientQuantity: 0.2,
        ingredientAmount: "კგ",
        ingredientAmountEn: "KG",
        ingredientId: 4,
      },
      {
        ingredientNameGe: "ბულგარული",
        ingredientNameEn: "bulgaruli",
        ingredientQuantity: 2,
        ingredientAmount: "ც",
        ingredientAmountEn: "I",
        ingredientId: 19,
      },
    ],
  },
  {
    productNameGe: "შემწვარი კარტოფილი",
    productNameEn: "Frie",
    sourse: potato,
    raund: 2,
    avgPrice: 10,
    ingredients: [
      {
        ingredientNameGe: "კარტოფილი",
        ingredientNameEn: "Potato",
        ingredientQuantity: 0.3,
        ingredientAmount: "კგ",
        ingredientAmountEn: "KG",
        ingredientId: 5,
      },
      {
        ingredientNameGe: "ზეთი",
        ingredientNameEn: "Oil",
        ingredientQuantity: 0.1,
        ingredientAmount: "ლ",
        ingredientAmountEn: "L",
        ingredientId: 20,
      },
    ],
  },
  {
    productNameGe: "ბრაუნი",
    productNameEn: "Braun",
    sourse: brownie,
    raund: 2,
    avgPrice: 14,
    ingredients: [
      {
        ingredientNameGe: "ფქვილი",
        ingredientNameEn: "Povder",
        ingredientQuantity: 0.1,
        ingredientAmount: "კგ",
        ingredientAmountEn: "KG",
        ingredientId: 1,
      },
      {
        ingredientNameGe: "შაქარი",
        ingredientNameEn: "Sugar",
        ingredientQuantity: 0.1,
        ingredientAmount: "კგ",
        ingredientAmountEn: "KG",
        ingredientId: 21,
      },
      {
        ingredientNameGe: "კვერცხი",
        ingredientNameEn: "Egg",
        ingredientQuantity: 4,
        ingredientAmount: "ც",
        ingredientAmountEn: "i",
        ingredientId: 19,
      },
      {
        ingredientNameGe: "კარაქი",
        ingredientNameEn: "Butter",
        ingredientQuantity: 0.25,
        ingredientAmount: "კგ",
        ingredientAmountEn: "KG",
        ingredientId: 22,
      },
      {
        ingredientNameGe: "შავი შოკოლადი",
        ingredientNameEn: "Black Chocolate",
        ingredientQuantity: 0.2,
        ingredientAmount: "კგ",
        ingredientAmountEn: "KG",
        ingredientId: 23,
      },
    ],
  },
  {
    productNameGe: "ნალექიანი ყავა",
    productNameEn: "Coffee",
    sourse: coffee,
    raund: 2,
    avgPrice: 6,
    ingredients: [
      {
        ingredientNameGe: "შაქარი",
        ingredientNameEn: "Sugar",
        ingredientQuantity: 0.01,
        ingredientAmount: "კგ",
        ingredientAmountEn: "KG",
        ingredientId: 21,
      },
      {
        ingredientNameGe: "ყავა",
        ingredientNameEn: "coffee",
        ingredientQuantity: 0.01,
        ingredientAmount: "კგ",
        ingredientAmountEn: "KG",
        ingredientId: 24,
      },
    ],
  },
  {
    productNameGe: "სტეიკი",
    productNameEn: "Stake",
    sourse: stake,
    raund: 3,
    avgPrice: 16,
    ingredients: [
      {
        ingredientNameGe: "საქონლის ხორცი",
        ingredientNameEn: "Beef",
        ingredientQuantity: 0.2,
        ingredientAmount: "კგ",
        ingredientAmountEn: "KG",
        ingredientId: 30,
      },
      {
        ingredientNameGe: "კარაქი",
        ingredientNameEn: "butter",
        ingredientQuantity: 0.1,
        ingredientAmount: "კგ",
        ingredientAmountEn: "KG",
        ingredientId: 22,
      },
    ],
  },
  {
    productNameGe: "სოკო სულგუნით",
    productNameEn: "Mushrooms",
    sourse: mushroom,
    raund: 3,
    avgPrice: 16,
    ingredients: [
      {
        ingredientNameGe: "ქამა სოკო",
        ingredientNameEn: "Mushroom",
        ingredientQuantity: 0.5,
        ingredientAmount: "კგ",
        ingredientAmountEn: "KG",
        ingredientId: 31,
      },
      {
        ingredientNameGe: "სულგუნი",
        ingredientNameEn: "Sulguni",
        ingredientQuantity: 0.1,
        ingredientAmount: "კგ",
        ingredientAmountEn: "KG",
        ingredientId: 32,
      },
    ],
  },
  {
    productNameGe: "ქლაბ სენდვიჩი",
    productNameEn: "Club Sendvich",
    sourse: club,
    raund: 3,
    avgPrice: 14,
    ingredients: [
      {
        ingredientNameGe: "პური",
        ingredientNameEn: "Bread",
        ingredientQuantity: 0.1,
        ingredientAmount: "კგ",
        ingredientAmountEn: "KG",
        ingredientId: 29,
      },
      {
        ingredientNameGe: "ქათმის ფილე",
        ingredientNameEn: "chicken",
        ingredientQuantity: 0.1,
        ingredientAmount: "კგ",
        ingredientAmountEn: "KG",
        ingredientId: 16,
      },
      {
        ingredientNameGe: "ლორი",
        ingredientNameEn: "Lori",
        ingredientQuantity: 0.1,
        ingredientAmount: "კგ",
        ingredientAmountEn: "KG",
        ingredientId: 34,
      },
      {
        ingredientNameGe: "მაიონეზი",
        ingredientNameEn: "maionezi",
        ingredientQuantity: 0.05,
        ingredientAmount: "კგ",
        ingredientAmountEn: "KG",
        ingredientId: 17,
      },
      {
        ingredientNameGe: "კეტჩუპი",
        ingredientNameEn: "tomato pasta",
        ingredientQuantity: 0.05,
        ingredientAmount: "კგ",
        ingredientAmountEn: "KG",
        ingredientId: 35,
      },
    ],
  },
  {
    productNameGe: "ყველის ჩხირები",
    productNameEn: "Fried Cheese",
    sourse: cheese,
    raund: 3,
    avgPrice: 22,
    ingredients: [
      {
        ingredientNameGe: "ჩედარი",
        ingredientNameEn: "Chedari",
        ingredientQuantity: 0.25,
        ingredientAmount: "კგ",
        ingredientAmountEn: "KG",
        ingredientId: 33,
      },
      {
        ingredientNameGe: "ფქვილი",
        ingredientNameEn: "Povder",
        ingredientQuantity: 0.2,
        ingredientAmount: "კგ",
        ingredientAmountEn: "KG",
        ingredientId: 1,
      },
      {
        ingredientNameGe: "კარაქი",
        ingredientNameEn: "butter",
        ingredientQuantity: 0.1,
        ingredientAmount: "კგ",
        ingredientAmountEn: "KG",
        ingredientId: 22,
      },
    ],
  },
  {
    productNameGe: "პური",
    productNameEn: "Bread",
    sourse: bread,
    raund: 3,
    avgPrice: 6,
    ingredients: [
      {
        ingredientNameGe: "პური",
        ingredientNameEn: "Bread",
        ingredientQuantity: 0.25,
        ingredientAmount: "კგ",
        ingredientAmountEn: "KG",
        ingredientId: 29,
      },
    ],
  },
  {
    productNameGe: "ლუდი",
    productNameEn: "Beer",
    sourse: beer,
    raund: 3,
    avgPrice: 6,
    ingredients: [
      {
        ingredientNameGe: "ლუდი",
        ingredientNameEn: "Beer",
        ingredientQuantity: 0.5,
        ingredientAmount: "ლ",
        ingredientAmountEn: "l",
        ingredientId: 36,
      },
    ],
  },
];

export default AllproductsDb;
