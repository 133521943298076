import React, { useState, useEffect } from "react";
import Task1Lvl4 from "./level4/Taks1Lvl4";
import { GetEmpName } from "./tools/tools";
const Marketing = () => {
  const token = localStorage.getItem("token");
  const uid = localStorage.getItem("uid");
  const [bonuses, setBonuses] = useState([]);
  const [empName, setEmpName] = useState("");
  const [marketingActivities, setMarketingActivities] = useState([]);
  const [level, setLevel] = useState("0");
  useEffect(() => {
    fetch(`https://www.simulator.ge/all_users`, {
      method: "Get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        data.forEach((item) => {
          if (item[0] == uid) {
            setLevel(item[5]);
          }
        });
      });
  }, []);
  useEffect(() => {
    fetch("https://www.simulator.ge/market_activity", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setMarketingActivities(data);
      });
  }, []);
  useEffect(() => {
    fetch("https://www.simulator.ge/emps", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setEmpName(data);
      });
  }, []);
  const getName = (id) => {
    let tmp = "";
    if (empName.length > 0) {
      empName.forEach((element) => {
        if (id == element[0]) {
          tmp = element[3] + " " + element[4];
        }
      });
    }
    return tmp;
  };
  useEffect(() => {
    fetch("https://www.simulator.ge/game_activity", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        let tmp = [];
        data.forEach((element) => {
          if (element[1] == uid) {
            tmp.push(element);
          }
        });
        setBonuses(tmp);
      });
  }, []);

  return (
    <div className="marketing_wrapper">
      <div className="marketing_activity">
        <h2>მარკეტინგული აქტივობები</h2>
        <h3>ბონუსები</h3>
        <table className="table">
          <thead>
            <tr>
              <th>თანამშრომელი</th>
              <th>მიღებული ბონუსი</th>
            </tr>
          </thead>
          <tbody>
            {bonuses
              ? bonuses.map((item) => {
                  if (item[4] == "true") {
                    return (
                      <tr key={item[0]}>
                        <td> {getName(item[3])}</td>
                        <td> {item[6]}</td>
                      </tr>
                    );
                  }
                })
              : null}
          </tbody>
        </table>
      </div>
      <div className="marketing_activity">
        <h3>ტრენინგები</h3>
        <table className="table">
          <thead>
            <tr>
              <th>თანამშრომელი</th>
              <th>მიღებული ბონუსი</th>
            </tr>
          </thead>
          <tbody>
            {bonuses
              ? bonuses.map((item) => {
                  if (item[5] == "true") {
                    return (
                      <tr key={item[0]}>
                        <td> {getName(item[3])}</td>
                        <td> {item[6]}</td>
                      </tr>
                    );
                  }
                })
              : null}
          </tbody>
        </table>
      </div>
      <div className="marketing_activity">
        <h3>პრომოცია</h3>
        <table className="table">
          <thead>
            <tr>
              <th>პლატფორმა</th>
              <th>ბიუჯეტი</th>
            </tr>
          </thead>
          <tbody>
            {marketingActivities
              ? marketingActivities.map((item) => {
                  if (item[1] == uid) {
                    return (
                      <tr key={item[0]}>
                        <td> {item[5]}</td>
                        <td> {item[3]}</td>
                      </tr>
                    );
                  }
                })
              : null}
          </tbody>
        </table>
      </div>
      {/* <div className="marketing_activity">
        <Task1Lvl4 level={level} />
      </div> */}
    </div>
  );
};
export default Marketing;
