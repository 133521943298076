import React, { useState, useEffect } from "react";
import AllproductsDb from "../resources/product";
import { ActionButton, Button } from "../newGame/tools/tools";
import { prodPrices } from "../resources/prodNames";
import { toast } from "react-toastify";
import { changeLevel, updateBudget } from "./tools/tools";
import Rank from "./Rank";
import MyPoints from "./MyPoints";
import { handleLogOut } from "../components/tools";
import Ratings from "./Ratings";

const GameFlow = (props) => {
  const uid = localStorage.getItem("uid");
  const token = localStorage.getItem("token");
  const gameId = localStorage.getItem("gameId");
  const [budget, setBudget] = useState([]); //ინფო ბიუჯეტზე
  const [money, setMoney] = useState(0); //თანხა ანგარიშზე
  const [level, setLevel] = useState("0");
  const [products, setProducts] = useState({});
  const [gameActivity, setGameActivity] = useState({});
  const [allProductsData, setAllProductsData] = useState({});
  const [allProductsFullPrice, setAllProductsFullPrice] = useState(0);
  const [groupUsers, setGroupUsers] = useState({});

  const [marketingPointsDb, setMarketingPointsDb] = useState(0);
  const [showCabinetProducts, setShowCabinetProducts] = useState(false);
  const [showCabinetEmps, setShowCabinetEmps] = useState(false);
  const [showCabinetInventar, setShowCabinetInventar] = useState(false);
  const [showCabinetReitings, setShowCabinetReitings] = useState(false);
  const [showCabinetStatistics, setShowCabinetStatistics] = useState(false);
  const [possibleSales, setPossibleSales] = useState(0);

  const [showProducts, setShowProducts] = useState(false); //პროდუქტის შექმნის ჩვენება
  const [showMarketingi, setShowMarketingi] = useState(false); //მარკეტინგის ჩვენება
  const [showRanks, setShowRanks] = useState(false);
  const [showEmpsPage, setShowEmpsPage] = useState(false); //თანამშრომლების ჩვენება პროდუქციაში
  const [showInventPage, setShowInventPage] = useState(false); //ინვენტარის ჩვენება
  const [showMarketPage, setShowMarketPage] = useState(false); //პროდუქციის დაბუსტვა და მარკეტინგი
  const [showMarketConfirmation,setMarketConfirmation] = useState(false)
  const [marketingPoint,setMarketingPoint] = useState(0)
  useEffect(()=>{
    if(parseInt(level)>0){
      if(marketingPointsDb.length>parseInt(level)-1){
        setMarketingPoint(marketingPointsDb[parseInt(level)-1][4])
      }
    }
  },[marketingPointsDb,level])
  const getSales = (level, gameId, userId) => {};
  let lang = props.lang;
  const language = {
    en: {
      budget: "Budget",
      round: "Round",
      createProduct: "Create Product",
      qtt: "Quantity",
      ingredients: "Ingredients",
      inventar: "Equipment",
      emps: "Employee",
      dayly: "Daily",
      hourly: "Hourly",
      self: "Per unit costs",
      piece: "p",
      piecePrice: "sale price",
      order: "Order",
      price: "Price",
      fullPrice: "Full Price",
      prodInven: "Production Inventory",
      desk: "Desk Equipment",
      cook: "Production",
      waitress: "Waitress",
      name: "Product Name",
      close: "Close",
      manage: "Manage",
      stats: "My Stats",
      products: "Products",
      sales: "Sales",
      rating: "Rating",
      marketing: "Marketing",
      empsQtt:"Staff",
      empsStars:"Stars",
      empsPrice:"Fees",
      pay:"Pay",
      stars:"Stars",
      invetarFull:'Inventar Price',
      invetarStars:'Stars',
      inventar:'Inventar',
      empsStars:"Employee Motivation",
      productStars:"Products Quiality",
      serviceStars:"Service Quality",
      salesPrice:"Products Full Price",
      marketingStars:"Marketing Stars",
      possibleSales:"Possible Sales",
      boost:"Boost",
      inventarStars:"Inventar Quality",
      inventarPrice:"Inventar Price",
      infoP1:"Please Finish The round",
      infoP2:"Please be accurate",
      finishRound:"Finish The Round",
      currency:"$"
      
    },
    ge: {
      budget: "ბიუჯეტი",
      round: "რაუნდი",
      createProduct: "პროდუქციის შექმნა",
      qtt: "რაოდენობა",
      ingredients: "ინგრედიენტები",
      inventar: "ინვენტარი",
      emps: "თანამშრომლები",
      dayly: "დღეში",
      hourly: "საათში",
      self: "თვითღირებულება",
      piece: "ც",
      piecePrice: "გასაყიდი ფასი",
      order: "შეკვეთა",
      price: "ფასი",
      fullPrice: "საერთო ფასი",
      prodInven: "საწარმოო ინვენტარი",
      desk: "სამაგიდო ინვენტარი",
      cook: "წარმოება",
      waitress: "მიმტანი",
      name: "პროდუქტის სახელი",
      close: "დახურვა",
      manage: "მენეჯერი",
      stats: "სტატისტიკა",
      products: "პროდუქტები",
      sales: "გაყიდვები",
      rating: "რანკი",
      marketing: "მარკეტინგი",
      empsQtt:"თანამშრომლები",
      empsStars:"ხარისხი",
      empsPrice:"ხელფასი",
      pay:"გადახდა",
      stars:"ხარისხი",
      invetarFull:'ინვენტარის ფასი',
      invetarStars:'ხარისხი',
      inventar:'ინვენტარი',
      empsStars:"თანამშრომელთა მოტივაცია",
      productStars:"პროდუქციის ხარისხი",
      serviceStars:"მომსახურების ხარისხი",
      salesPrice:"პროდუქციის გასაყიდი ფასი",
      marketingStars:"მარკეტინგის ხარისხი",
      possibleSales:"სავარაუდო გაყიდვები",
      boost:"დარეკლამება",
      inventarStars:"ინვენტარის ხარისხი",
      inventarPrice:"ინვენტარის ფასი",
      infoP1:"გთხოვთ, დაასრულეთ რაუნდი",
      infoP2:"რაუნდის დასრულების შემდეგ, შეამოწმეთ რანკი",
      finishRound:"რაუნდის დასრულება",
      currency:"₾"
    },
  };
  let chosen = language[lang];

  useEffect(() => {
    if (gameActivity[parseInt(level) - 1]) {
      setPossibleSales(
        (allProductsFullPrice *
          (+gameActivity[parseInt(level) - 1][4] +
            +gameActivity[parseInt(level) - 1][6] +
            1 +
            parseInt(marketingPoint))) /
          10
      );
    }
  }, [gameActivity,allProductsFullPrice]);
  useEffect(() => {
    fetch(`https://www.simulator.ge/market_activity`, {
      method: "Get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        const filtered = data.filter((item) => item[2] == gameId && item[1]==uid);
        if(filtered.length>0){
          setMarketingPointsDb(filtered);
        }
      });
  }, [money]);
  const saveSales = (gameId, uid, possibleSales, level) => {
    const dt = {
      prod_pasi: gameId,
      prod_raod: uid,
      prod_name: possibleSales,
      prod_info: level,
    };
    fetch(`https://www.simulator.ge/add_gayidvebi`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(dt),
    })
      .then((response) => response.json())
      .then((data) => {
        toast("Complete gaiyida");
      });
  };
  const finishLevel = () => {
    saveSales(gameId, uid, possibleSales, level);
    updateBudget(gameId, uid, possibleSales);
    changeLevel(1 + parseInt(level));
  };
  const addMarketActivity = () => {
    let marketingVages = allProductsFullPrice / 10
    checkAvalability(marketingVages);
    let dt = {
      kategoria: level,
      user_id: uid,
      pasi: marketingVages,
      vada: marketingPoint,
      game_id: gameId,
    };
    if(marketingPoint===0 && parseInt(marketingVages) < money){
      dt.vada = marketingPoint+1
      fetch(`https://www.simulator.ge/add_market_activity`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(dt),
      })
        .then((response) => response.json())
        .then((data) => {
          toast("Complete");
        });
      setMarketingPoint(prevState => prevState++)
      updateBudget(gameId, uid, -marketingVages);
      setMoney((prevState) => prevState - marketingVages);
    }else if(marketingPoint>0 && parseInt(marketingVages) < money){
      let dt = {
        user_id: marketingPointsDb[parseInt(level)-1][1],
        game_id: marketingPointsDb[parseInt(level)-1][2],
        pasi: parseInt(allProductsFullPrice/10) + parseInt(marketingPointsDb[parseInt(level)-1][3]) ,
        vada: 1 + parseInt(marketingPointsDb[parseInt(level)-1][4]),
        kategoria: marketingPointsDb[parseInt(level)-1][5],
      };
      fetch(`https://www.simulator.ge/edit_market_activity/${marketingPointsDb[parseInt(level)-1][0]}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(dt),
      })
        .then((response) => response.json())
        .then((data) => {
          toast("Complete");
        });
      setMarketingPoint(prevState => prevState++)
      updateBudget(gameId, uid, -marketingVages);
      setMoney((prevState) => prevState - marketingVages);
    
    }


  };

  const checkAvalability = (amount) => {
    if (parseInt(amount) > money) {
      alert("არ გაქვთ სამარისი თანხა");
    }
  };
  useEffect(() => {
    fetch(`https://www.simulator.ge/gayidvebis_baza`, {
      method: "Get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        const filtered = data.filter(
          (item) => item[1] === uid && item[4] == level
        );
        setAllProductsData(filtered);
        let sum = 0;
        filtered.forEach((item) => {
          let prc = parseInt(item[5]) * parseInt(item[6]);
          sum += prc;
        });
        setAllProductsFullPrice(sum);
      });
  }, [money,gameActivity]);

  useEffect(() => {
    fetch(`https://www.simulator.ge/game_activity`, {
      method: "Get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        const filtered = data.filter((item) => item[1] === uid);
        setGameActivity(filtered);
      });
  }, [money]);
  useEffect(() => {
    fetch(`https://www.simulator.ge/yidvis_baza`, {
      method: "Get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        const filtered = data.filter((item) => item[1] === uid);
        setProducts(filtered);
      })
      .catch((error) => handleLogOut());
  }, []);
  useEffect(() => {
    fetch(`https://www.simulator.ge/all_users`, {
      method: "Get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        data.forEach((item) => {
          if (item[0] == uid) {
            setLevel(item[5]);
          }
        });
      });
  }, []);
  useEffect(() => {
    if (budget.length) {
      setMoney(budget[0][2]);
    }
  }, [budget]);
  useEffect(() => {
    fetch(`https://www.simulator.ge/budget`, {
      method: "Get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        let filtered = data.filter((item) => item[1] === uid);
        setBudget(filtered);
      });
  }, [money]);
  // handlers
  const handleCabinetProduct = () => {
    setShowCabinetStatistics(false);
    setShowCabinetEmps(false);
    setShowCabinetProducts(true);
    setShowCabinetInventar(false);
  };
  const handleCabinetEmps = () => {
    setShowCabinetStatistics(false);
    setShowCabinetEmps(true);
    setShowCabinetProducts(false);
    setShowCabinetInventar(false);
  };
  const handleCabinetProductInventar = () => {
    setShowCabinetStatistics(false);
    setShowCabinetEmps(false);
    setShowCabinetProducts(false);
    setShowCabinetInventar(true);
  };
  const handleCabinetProductStatistics = () => {
    setShowCabinetStatistics(true);
    setShowCabinetEmps(false);
    setShowCabinetProducts(false);
    setShowCabinetInventar(false);
  };
  const handleCabinetReitings = () => {
    setShowCabinetReitings(true);
    setShowProducts(false);
    setShowMarketingi(false);
    setShowRanks(false);
  };
  const handleRanksClick = () => {
    setShowProducts(false);
    setShowMarketingi(false);
    setShowRanks(true);
    setShowCabinetReitings(false);
  };

  const handleClick = () => {
    setShowProducts(true);
    setShowMarketingi(false);
    setShowRanks(false);
    setShowCabinetReitings(false);

  };
  const handleEmployeeClick = () => {
    setShowEmpsPage(true);
    setShowInventPage(false);
    setShowMarketPage(false);
  };
  const handleInventarClick = () => {
    setShowEmpsPage(false);
    setShowInventPage(true);
    setShowMarketPage(false);
  };
  const handleMarketingClick = () => {
    setShowEmpsPage(false);
    setShowInventPage(false);
    setShowMarketPage(true);
  };
  const handleMarketingiCLick = () => {
    setShowMarketingi(true);
    setShowProducts(false);
    setShowRanks(false);
    setShowCabinetReitings(false);

  };

  const payTraining = () => {
    let bill = (gameActivity[parseInt(level) - 1][3] * 1200) / 5;
    checkAvalability(bill);
    toast("Complete");
    updateBudget(gameId, uid, -bill);
    setMoney((prevState) => prevState - bill);
    updateEmpsQuality(gameActivity, 1, "emp");
  };
  const updateInventar = () => {
    let bill = (gameActivity[parseInt(level) - 1][5] * 720) / 5;
    checkAvalability(bill);
    toast("Complete");

    updateBudget(gameId, uid, -bill);
    setMoney((prevState) => prevState - bill);
    updateEmpsQuality(gameActivity, 1, "inventar");
  };
  const updateEmpsQuality = (gameAct, xarisxi, mode) => {
    const uid = gameAct[parseInt(level) - 1][0];
    let gameActivityDb = {};
    if (mode === "emp") {
      gameActivityDb = {
        user_id: gameAct[parseInt(level) - 1][1],
        game_id: gameAct[parseInt(level) - 1][2],
        emps_id: gameAct[parseInt(level) - 1][3],
        bonus: +gameAct[parseInt(level) - 1][4] + xarisxi,
        training: gameAct[parseInt(level) - 1][5],
        tanxa: gameAct[parseInt(level) - 1][6],
        levels: parseInt(gameId),
      };
    } else if (mode === "inventar") {
      gameActivityDb = {
        user_id: gameAct[parseInt(level) - 1][1],
        game_id: gameAct[parseInt(level) - 1][2],
        emps_id: gameAct[parseInt(level) - 1][3],
        bonus: gameAct[parseInt(level) - 1][4],
        training: gameAct[parseInt(level) - 1][5],
        tanxa: +gameAct[parseInt(level) - 1][6] + xarisxi,
        levels: parseInt(gameId),
      };
    }
    fetch(`https://www.simulator.ge/edit_game_activity/${uid}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(gameActivityDb),
    })
      .then((response) => response.json())
      .then((data) => {
        // window.location.reload(false)
      });
  };
  const Product = (props) => {
    const [quantity, setQuantity] = useState(0); //პროდუქტის რაოდენობა
    const [productFullprice, setProductFullprice] = useState(0); //ინგედიენტების ფასები
    const [showIngredients, setShowIngredients] = useState(false); //ინგრედიენტების ჩვენება
    const [showInventar, setShowInventar] = useState(false); //ინვენტარის ჩვენება
    const [showEmps, setShowEmps] = useState(false); //თანამშრომლების ჩვენება
    const [prodPrice, setProdPrice] = useState(0); //გასაყიდი ფასი
    const [showConfirmation, setShowConfirmation] = useState(false); //გადახდის ჩვენება

    const handleConfirmation = () => {
      setShowConfirmation(true);
    };
    const handleChange = (e) => {
      if (e.target.value > 800) {
        setProductFullprice(0);
        setQuantity(800);
      } else if (e.target.value <= 800) {
        setProductFullprice(0);
        setQuantity(e.target.value);
      }
    };
    const item = props.item;
    const fullPrice = {};
    useEffect(() => {
      if (quantity > 0) {
        item.ingredients.forEach((ingredient) => {
          let price = prodPrices[ingredient.ingredientId];
          let fullPric = price * quantity * ingredient.ingredientQuantity;
          setProductFullprice((prevState) => prevState + fullPric);
          fullPrice[ingredient.ingredientId] = fullPric;
        });
      }
    }, [quantity]);
    if (Object.keys(fullPrice).length > 0) {
      let sum = 0;
      fullPrice.forEach((price) => {
        sum += price;
      });
    }
    const handleIngredientClick = () => {
      setShowIngredients(true);
      setShowInventar(false);
      setShowEmps(false);
    };
    const handleInventarClick = () => {
      setShowInventar(true);
      setShowIngredients(false);
      setShowEmps(false);
    };
    const handleEmpsClick = () => {
      setShowEmps(true);
      setShowIngredients(false);
      setShowInventar(false);
    };
    const handleChangePrice = (e) => {
      let min = fulAm / quantity;
      let max = (fulAm / quantity) * 5;
      let price = e.target.value;
      if (price > min && price < max) {
        setProdPrice(e.target.value);
      } else {
        alert("მიუთითეთ ლოგიკური ფასი");
      }
    };
    let daylyQtt = quantity / 30;
    let houreQtt = daylyQtt / 8;
    const mzareuli = 0.13;
    const mimtani = 0.13;
    const satcxobi = 0.13;
    const magidisInventari = 0.13;
    let needMzareuli = Math.round(houreQtt * mzareuli) +1;
    let needMimtani = Math.round(houreQtt * mimtani) + 1;
    let needsacxobi = Math.round(houreQtt * satcxobi) +1;
    let needmagidisInventari = Math.round(houreQtt * magidisInventari)+1;
    let inventarPrice = needsacxobi * 600 + needmagidisInventari * 120;
    let EmpsPrice = needMzareuli * 1200 + needMimtani * 600;
    let fulAm = EmpsPrice + inventarPrice + productFullprice;
    let produqtisSaxeli = lang==='en'? item.productNameEn :item.productNameGe
    let produqtisRaodenoba = quantity;
    let biujetsUndaDaakldes = fulAm;
    let tanamshromeliDaemata = needMimtani + needMzareuli;
    let tanamshromlebisXarisxi = 1;
    let tanamshromleibsXelfasi = EmpsPrice;
    let gasayidiFasi = prodPrice;
    let inventarisRaodenoba = needsacxobi + needMimtani;
    let inventariSfasi = inventarPrice;
    let createProductDb = {
      user_id: uid,
      game_id: gameId,
      product_id: produqtisSaxeli,
      xarisxi: level,
      pasi: gasayidiFasi,
      raodenoba: quantity,
    };
    let gameActivityDb = {
      user_id: uid,
      game_id: level,
      emps_id: tanamshromeliDaemata,
      bonus: 1,
      training: inventarisRaodenoba,
      tanxa: 1,
      levels: parseInt(gameId),
    };
    const createProduct = () => {
      let bill = fulAm;
      checkAvalability(bill);
      if (bill < money) {
        fetch(`https://www.simulator.ge/add_gayidvebis_baza`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(createProductDb),
        })
          .then((response) => response.json())
          .then((data) => {
            toast(`Complete`);
            // window.location.reload(false)
          });
        if (gameActivity[parseInt(level) - 1]) {
          gameActivityDb.emps_id += parseInt(
            gameActivity[parseInt(level) - 1][3]
          );
          gameActivityDb.training += parseInt(
            gameActivity[parseInt(level) - 1][5]
          );
          fetch(
            `https://www.simulator.ge/edit_game_activity/${
              gameActivity[parseInt(level) - 1][0]
            }`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
              body: JSON.stringify(gameActivityDb),
            }
          )
            .then((response) => response.json())
            .then((data) => {
              toast(`Complete`);
              updateBudget(gameId, uid, -fulAm);
              setMoney((prevState) => prevState - fulAm);
              // window.location.reload(false)
            });
        } else if (!gameActivity[parseInt(level) - 1]) {
          fetch(`https://www.simulator.ge/add_game_activity`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(gameActivityDb),
          })
            .then((response) => response.json())
            .then((data) => {
              updateBudget(gameId, uid, -fulAm);
              setMoney((prevState) => prevState - fulAm);
              // window.location.reload(false)
            });
        }
      }
    };

    return (
      <div className="w-full flex items-center bg-readableBg p-6 mb-2 mr-1 flex-col">
        <h2 className="text-xl mb-2 text-bold">{lang==='en'? item.productNameEn :item.productNameGe}</h2>
        <div style={{ width: "90px", height: "90px" }}>
          <img src={item.sourse} className="rounded-xl" />
        </div>
        <input
          id="inputQtt"
          type="number"
          className="rounded p-1 m-1 text-dark text-center m-2"
          max="500"
          placeholder={chosen.qtt}
          onBlur={handleChange}
        ></input>
        <div className="m-2" style={{ width: "500px" }}>
          <div className="header flex justify-center">

      
          <button
            className="bg-buttonColor p-2 rounded-md drop-shadow-xl mr-2"
            onClick={handleIngredientClick}
          >
            {chosen.ingredients}
          </button>
          <button
            className="bg-buttonColor p-2 rounded-md drop-shadow-xl mr-2"
            onClick={handleInventarClick}
          >
            {chosen.inventar}
          </button>
          <button
            className="bg-buttonColor p-2 rounded-md drop-shadow-xl mr-2"
            onClick={handleEmpsClick}
          >
            {chosen.emps}
          </button>
          </div>
          <div className="m-2 flex flex-col items-center">
            <div className="">{chosen.dayly} {Math.round(daylyQtt)} {chosen.piece}</div>
            <div className="">{chosen.hourly} {Math.round(houreQtt)} {chosen.piece} </div>
            <div className="">{chosen.self} {Math.round((fulAm / quantity) * 100) / 100 || 0} {chosen.currency}.</div>
          </div>
          <div hidden={!showIngredients}>
            {item.ingredients.map((prod) => (
              <div className="flex w-full p-1 items-center flex-col" key={prod.ingredientId}>
                <div className="">{lang==='en'? prod.ingredientNameEn:prod.ingredientNameGe} </div>
                <div className="">{chosen.qtt} {Math.round(prod.ingredientQuantity * quantity * 10) / 10}{" "}</div>
                <div className=""> {chosen.price} : {Math.round(prod.ingredientQuantity * quantity *prodPrices[prod.ingredientId] *10) / 10}</div>
              </div>
            ))}
            <div className="flex justify-center">
              {chosen.fullPrice}: {productFullprice}
            </div>
          </div>
          <div className="flex flex-col items-center " hidden={!showInventar}>
    
            <div>{chosen.prodInven} : {needsacxobi} </div>
            <div>{chosen.price} : {needsacxobi * 600}</div>
   
   
            <div>{chosen.desk} : {needmagidisInventari}</div>
            <div>{chosen.price} : {needmagidisInventari * 120}</div>
 
          </div>
          <div className="flex flex-col items-center" hidden={!showEmps}>
            <div>{chosen.cook} : {needMzareuli} {chosen.price} : {needMzareuli * 1200}</div>
            <div> {chosen.waitress} : {needMimtani} {chosen.price} : {needMimtani * 600}</div>
            
           
          </div>
        </div>
        <input
          type="text"
          className="rounded p-1 m-1 text-dark text-center"
          placeholder={chosen.piecePrice}
          onBlur={handleChangePrice}
        ></input>
        <button
          className="p-2 m-2 bg-buttonColor rounded-xl drom-shadow-xl hover:bg-confirmGreen"
          onClick={handleConfirmation}
        >
          {chosen.order}
        </button>
        {showConfirmation && (
          <div className="h-1/3 w-1/3 bg-dark absolute rounded p-3 flex flex-col items-center top-10 bottom-0 margin-auto flex flex-col items-center">
            <div className="">
              {chosen.name}: {lang==='en'? item.productName :item.productNameGe}
            </div>
            <div className="">
              {chosen.qtt} : {quantity}
            </div>
            <div className="">
              {chosen.self} : {fulAm}
            </div>
            <div className="">
              {chosen.price}: {prodPrice}
            </div>
            <button
              onClick={createProduct}
              className="p-2 m-2 bg-buttonColor rounded-xl"
            >
              {chosen.order}
            </button>
            <button
              onClick={() => {
                setShowConfirmation(false);
              }}
              className="p-2 m-2 bg-brightRed rounded-xl"
            >
              {chosen.close}
            </button>
          </div>
        )}
      </div>
    );
  };

  return (
    <div
      className="flex flex-wrap justify-center overflow-x-auto"
      style={{
        maxHeight: "80vh",
      }}
    >
      <div className="p-2 fixed" style={{ marginTop: "-30px" }}>
 
        {chosen.budget} : {money} {chosen.currency}
 
   
         {chosen.round} N- {level}
   
      </div>
      <div className="w-full flex justify-center mb-6">
        <button
          className="bg-buttonColor p-2 rounded-md drop-shadow-xl mr-2"
          onClick={handleClick}
        >
          {chosen.createProduct}
        </button>
        {gameActivity[parseInt(level) - 1] && (
          <>
            <button
              className="bg-buttonColor p-2 rounded-md drop-shadow-xl mr-2"
              onClick={handleMarketingiCLick}
            >
              {chosen.manage}
            </button>
            <button
              className="bg-buttonColor p-2 rounded-md drop-shadow-xl mr-2"
              onClick={handleRanksClick}
            >
              {chosen.stats}
            </button>
            <button
              className="bg-buttonColor p-2 rounded-md drop-shadow-xl mr-2"
              onClick={handleCabinetReitings}
            >
              {chosen.rating}
            </button>
          </>
        )}
      </div>
      <div className="flex flex-col-reverse items-center">
        {showProducts &&
          AllproductsDb.filter((item) => item.raund <= level).map((item) => (
            <Product item={item} key={item.productNameEn} />
          ))}
      </div>
      {showMarketingi && (
        <>
          <div className="w-full flex flex-col items-center">
            <div className="w-full flex justify-center">
              <div
                className="p-2 bg-buttonColor m-2 rounded-xl box-shadow-xl hover:cursor-pointer"
                onClick={handleEmployeeClick}
              >
                {chosen.emps}
              </div>
              <div
                className="p-2 bg-buttonColor m-2 rounded-xl box-shadow-xl hover:cursor-pointer"
                onClick={handleInventarClick}
              >
                {chosen.inventar}
              </div>
              <div
                className="p-2 bg-buttonColor m-2 rounded-xl box-shadow-xl hover:cursor-pointer"
                onClick={handleMarketingClick}
              >
                {chosen.marketing}
              </div>
            </div>
            {showEmpsPage && (
              <div className="w-3/4 flex flex-col items-center bg-readableBg rounded-xl drop-shadow-xl p-3 m-3">
                <div className="m-2">
                  {chosen.empsQtt} : {gameActivity[parseInt(level) - 1][3]}
                </div>
                <div className="m-2">
                  {chosen.empsStars} : {gameActivity[parseInt(level) - 1][4]}
                </div>
                <div className="m-2">
                  {chosen.empsPrice} :{" "}
                  {gameActivity[parseInt(level) - 1][3] * 1200}
                </div>
                <div></div>
                {gameActivity[parseInt(level) - 1][4] < 3 && (
                  <div className="">
                    <button
                      className="p-2 bg-buttonColor rounded-xl drop-shadow-xl m-2"
                      onClick={payTraining}
                    >
                      {chosen.pay}
                    </button>
                    {chosen.training}: {chosen.price} :
                    {(gameActivity[parseInt(level) - 1][3] * 1200) / 5} {chosen.currency},
                    {chosen.stars} : + 1
                  </div>
                )}
              </div>
            )}
          </div>
          {showInventPage && (
            <div className="w-full flex flex-col items-center">
              <div className="w-3/4 flex flex-col items-center bg-readableBg rounded-xl drop-shadow-xl p-3 m-3">
                <div className="m-2">
                  {chosen.invetarFull}
                  {gameActivity[parseInt(level) - 1][5] * 720}
                </div>
                <div className="m-2">
                {chosen.invetarStars}: {gameActivity[parseInt(level) - 1][6]}
                </div>
                <div>
                  <div></div>
                </div>
                {gameActivity[parseInt(level) - 1][6] < 3 && (
                  <div className="">
                    <button
                      className="p-2 bg-buttonColor rounded-xl drop-shadow-xl m-2"
                      onClick={updateInventar}
                    >
                      {chosen.pay}
                    </button>
                    {chosen.price} : {(gameActivity[parseInt(level) - 1][5] * 720) / 5} {chosen.currency}
                    {chosen.stars} : + 1
                  </div>
                )}
              </div>
            </div>
          )}
          {showMarketPage && (
            <div className="w-full flex flex-col items-center">
              <div className="w-3/4 flex flex-col items-center bg-readableBg rounded-xl drop-shadow-xl p-3 m-3">
                <div className="m-2">
                  {chosen.empsPrice} :{" "}
                  {gameActivity[parseInt(level) - 1][3] * 1200}
                </div>
                <div className="m-2">
                  {chosen.empsStars} :{" "}
                  {gameActivity[parseInt(level) - 1][4]}
                </div>
                <div className="m-2">
                  {chosen.productStars} : {gameActivity[parseInt(level) - 1][6]}
                </div>
                <div className="m-2">
                  {chosen.serviceStars} : {gameActivity[parseInt(level) - 1][4]}
                </div>
                <div className="m-2">
                  {chosen.salesPrice}: {allProductsFullPrice}
                </div>
                <div className="m-2">
                  {chosen.marketingStars} : {marketingPoint}
                </div>
                <div className="m-2">{chosen.possibleSales} :{possibleSales}</div>
                {marketingPoint < 3 && (
                  <div>
                    <button
                      className="p-2 bg-buttonColor rounded-xl drop-shadow-xl m-2 "
                      onClick={()=>setMarketConfirmation(true)}
                    >
                      {chosen.boost}
                    </button>
                    {parseInt(allProductsFullPrice*0.1)} {chosen.stars} + 1
                    {
                      showMarketConfirmation && 

                    <div className="absolute top-10 p-2 flex items-center justify-center bg-readableBg rounded-xl ">
                      {chosen.price} = {parseInt(allProductsFullPrice*0.1)}
                      <button
                      className="p-2 bg-buttonColor rounded-xl drop-shadow-xl m-2 "
                      onClick={addMarketActivity}
                    >
                      {chosen.boost}
                    </button>
                    <button
                      className="p-2 bg-brightRed rounded-xl drop-shadow-xl m-2 "
                      onClick={()=>setMarketConfirmation(false)}
                    >
                      {chosen.close}
                    </button>
                    </div>
                     
                    }
                  </div>
                )}
              </div>
            </div>
          )}
        </>
      )}
      {showRanks && (
        <div>
          <div className="flex justify-center ">
            <button
              className="bg-buttonColor p-2 rounded-md drop-shadow-xl mr-2"
              onClick={handleCabinetProduct}
            >
              {chosen.products}
            </button>
            <button
              className="bg-buttonColor p-2 rounded-md drop-shadow-xl mr-2"
              onClick={handleCabinetEmps}
            >
              {chosen.emps}
            </button>
            <button
              className="bg-buttonColor p-2 rounded-md drop-shadow-xl mr-2"
              onClick={handleCabinetProductInventar}
            >
              {chosen.inventar}
            </button>
            <button
              className="bg-buttonColor p-2 rounded-md drop-shadow-xl mr-2"
              onClick={handleCabinetProductStatistics}
            >
              {chosen.sales}
            </button>
          </div>
          {showCabinetProducts && (
            <div className="">
              {allProductsData.map((item) => (
                <div
                  className="bg-readableBg p-6 mt-2 rounded-xl flex flex-col items-center"
                  key={item[0]}
                >
                  <div> {chosen.name} : {item[3]} </div>
                  <div> {chosen.qtt} : {item[6]} </div>
                  <div> {chosen.price} : {item[5]} </div>
                </div>
              ))}
            </div>
          )}
          {showCabinetEmps && (
            <div className="bg-readableBg p-6 mt-2 rounded-xl flex flex-col items-center">
              <div>
                {chosen.empsQtt} : {gameActivity[parseInt(level) - 1][3]}
              </div>
              <div>
                {chosen.empsStars} : {gameActivity[parseInt(level) - 1][4]}
              </div>
              <div>
                {chosen.empsPrice} :{" "}
                {gameActivity[parseInt(level) - 1][3] * 1200} {chosen.currency}
              </div>
            </div>
          )}
          {showCabinetInventar && (
            <div className="bg-readableBg p-6 mt-2 rounded-xl flex flex-col items-center">
              <div>
                {chosen.inventarStars} : {gameActivity[parseInt(level) - 1][6]}
              </div>
              <div>
                {chosen.inventarPrice}: {gameActivity[parseInt(level) - 1][5] * 720} {chosen.currency}
              </div>
            </div>
          )}
          {showCabinetStatistics && (
            <div className="bg-readableBg p-6 mt-2 rounded-xl flex flex-col items-center">
              <p className="">
      
                {chosen.infoP1}
              </p>
              <p>
              {chosen.infoP2}
              </p>
              <button
                className="bg-buttonColor p-2 rounded-md drop-shadow-xl mt-4"
                onClick={finishLevel}
              >
                {chosen.finishRound}
              </button>
            </div>
          )}
        </div>
      )}
      {showCabinetReitings && (
          <Ratings token={token} gameId={gameId} uid={uid} level={level} lang={lang}/>
      )}
    </div>
  );
};

export default GameFlow;
