import "./admin.css";
import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));
const AddGroup = () => {
  const [leagues, setLeagues] = useState(undefined);
  const [expanded, setExpanded] = React.useState("panel1");
  const [leagueName, setLeagueName] = useState("");
  const [participants, setParticipants] = useState("");
  const [canRegister, setCanRegister] = useState(false);
  const uid = localStorage.getItem("uid");
  const token = localStorage.getItem("token");
  const descr = document.getElementById("notice_name");
  const groupNameInput = document.getElementById("groupname_name");
  const [value, setValue] = React.useState(dayjs("2022-11-27"));
  function BasicDateTimePicker() {
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateTimePicker
          renderInput={(props) => <TextField {...props} />}
          label="დაწყების დრო"
          value={value}
          onChange={(newValue) => {
            setValue(newValue);
          }}
        />
      </LocalizationProvider>
    );
  }
  useEffect(() => {
    fetch("https://www.simulator.ge/leagues", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setLeagues(data);
      });
  }, []);
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const handleRegister = () => {
    let secondRound = new Date(value + 604800000);
    let thirdRound = new Date(value + 604800000 * 2);
    let data = {
      user_id: value.$d,
      game_id: secondRound,
      budget: participants,
      leagues_name: leagueName,
      leagues_id: thirdRound,
    };
    fetch(`https://www.simulator.ge/add_leagues`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify([data]),
    })
      .then((response) => response.json())
      .then((data) => {});
    toast("ჯგუფი დამატებულია");
  };
  const saveLeagueName = (e) => {
    leagues.forEach((item) => {
      if (item[5] === e.target.value) {
        descr.innerHTML = "ჯგუფის სახელი დაკავებულია";
        groupNameInput.style.border = "2px solid red";
        descr.style.color = "red";
        setCanRegister(false);
      } else {
        setCanRegister(true);
        descr.innerHTML = "ჯგუფის სახელი უნიკალურია";
        groupNameInput.style.border = "1px solid green";
        descr.style.color = "black";
        setLeagueName(e.target.value);
      }
    });
  };
  const saveParticipants = (e) => {
    setParticipants(e.target.value);
  };
  let difference = value.$d - new Date();
  const handleDelete = (groupUid)=>{
    fetch(`https://www.simulator.ge/delete_leagues/${groupUid}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        toast("ჯგუფი წაშლილია");

      });
    
    

  }
    const deleteBaza = (uid)=>{

      fetch(`https://www.simulator.ge/delete_gayidvebis_baza/${uid}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
        })
    }

 

  return (
    <div className="addgroup">
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography>ჯგუფების დამატება</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div>
            <div className="form-wrapper">
              <form>
                <div className="form-group">
                  <label htmlFor="groupname">ჯგუფის სახელი</label>
                  <input
                    type="text"
                    className="form-control"
                    id="groupname_name"
                    aria-describedby="groupname"
                    placeholder="ჯგუფის სახელი"
                    onBlur={saveLeagueName}
                  />
                  <small id="notice_name" className="form-text">
                    შეარჩიე ჯგუფის სახელი
                  </small>
                </div>

                <div className="form-group">
                  <label htmlFor="useramount">მონაწილეთა რაოდენობა</label>
                  <input
                    type="number"
                    className="form-control"
                    id="useramount"
                    placeholder="რაოდენობა"
                    onChange={saveParticipants}
                  />
                </div>
                <div className="date-picker-wrapper">
                  <label htmlFor="useramount">
                    აარჩიეთ ლიგის დაწყების თარიღი
                  </label>
                  <BasicDateTimePicker />
                </div>
              </form>
              {canRegister && (
                <button
                  type="submit"
                  className="btn btn-primary"
                  onClick={handleRegister}
                >
                  დარეგისტრირება
                </button>
              )}
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel3"}
        onChange={handleChange("panel3")}
      >
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography>ჯგუფის წაშლა</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div>
            <div className="form-wrapper">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>ჯგუფის სახელი</th>
                    <th>წაშლა</th>
                  </tr>
                </thead>
                <tbody>
                  {leagues && leagues.map ((league,i)=>(
                    <tr key={i}>
                      <th>{i+1}</th>
                      <th>{league[5]}</th>
                      <button onClick={()=>{handleDelete(league[0])}}>წაშლა</button>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
export default AddGroup;
