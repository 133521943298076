import {useState,useEffect} from 'react'
import { Outlet, Route, Routes, useNavigate } from "react-router-dom";
import Admin from "./components/admin/Admin";
import AddGroup from "./components/admin/AddGroup";
import AddUser from "./components/admin/AddUser";
import AdminLanding from "./components/admin/AdminLanding";
import { SendMail } from './components/admin/SendMail';
import Groups from './components/admin/Groups';
import Users from './components/admin/Users';
import Notifications from './components/admin/Notifications';
import Rest from './components/admin/Rest';
import User from './components/user/User';
import Game from './game/Game';
import AddProduct from './components/admin/AddProduct';
import AddAdmin from './components/admin/AddAdmin';
const Profile = (props) => { 
  const [user,setUser] = useState('')
  const username = JSON.parse(localStorage.getItem('user'));
  const lang =props.lang
  const navigate = useNavigate()
  useEffect(() => {
    if (username) {
     setUser(username);
    }else {
      navigate('/')
    }
  },[username]);
  return (
    <div>
    <Routes>
      <Route index element={<AdminLanding />} />
      <Route path="admin/" element={<Admin  user='admin'/>} />
      <Route path="admin/addgroup/" element={<AddGroup  />} />
      <Route path="admin/adduser/" element={<AddUser  />} />
      <Route path="admin/addadmin/" element={<AddAdmin  />} />
      <Route path="admin/addproduct/" element={<AddProduct  />} />
      <Route path="admin/sendmail/" element={<SendMail  />} />
      <Route path="admin/groups/" element={<Groups />} />
      <Route path="admin/users/" element={<Users />} />
      <Route path="admin/notifications/" element={<Notifications />} />
      <Route path="admin/rest/" element={<Rest />} />
      <Route path="user" element={<User user={user} lang={lang}/>}/>
      <Route path="user/game/*" element={<Game lang={lang}/>} />
    </Routes>
    
    </div>
  );
};

export default Profile;
