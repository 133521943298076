import React,{useState,useEffect} from 'react'
import {Button, handleLogOut}from '../../tools/tools'
import {Link} from 'react-router-dom'
import UserMenu from './UserMenu'

const Menu = (props)=>{
    const [user,setUser] = useState(props.user)
    const lang = props.lang
    const handleClick = ()=>{
        handleLogOut()
    }
    const language = {
        en:{
            login:"Login",
            register:"Register",
            about:"About us",
            contacts:"Contacts",
            logout:"Log Out"
        },
        ge:{
            login:"შესვლა",
            register:"რეგისტრაცია",
            about:"ჩვენს შესახებ",
            contacts:"კონტაქტი",
            logout:"გასვლა"
        }
    }
    return (
    <div className="h-auto w-1/5 bg-darkBlue text-white right-0 absolute fixed overflow-hidden p-6 flex flex-col items-start drop-shadow-xl z-40" style={{
        top:"10vh",
        bottom:'0'
    }}>
        {user && <UserMenu lang={lang}/> }
        {!user && <> 
        <Link to='login/'>
        <Button text={language[lang].login}/>
        </Link>
        <Link to='register' > 
        <Button text={language[lang].register}/>
        </Link>
        </>
        }


        <Button text={language[lang].about}/>
        <Button text={language[lang].contacts}/>

        <Button text={language[lang].logout} onClick={handleClick}/>
    </div>
    )
}
export default Menu