import {useNavigate} from 'react-router-dom'
const Landing= ()=> {
  const navigate=useNavigate()
  return <div className="flex flex-col ">
      <h1 className='text-3xl text-center m-4'>Welcome</h1>
      <h2 className="text-xl text-center m-4"> Please <button onClick={()=>navigate('./login')}>Log in </button>  </h2>
    
    </div>
}

export default Landing;