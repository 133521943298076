import React, { useState, useEffect } from "react";
import ChartJsApp from "./chart/Chart";
// import { Chart } from './chart';

const MyPoints = (props) => {
  const token = localStorage.getItem("token");
  const uid = localStorage.getItem("uid");
  const gameId = localStorage.getItem("gameId");
  const [groupMembers, setGroupMembers] = useState([]);
  const [customerSatisfaction, setCustomerSatisfcation] = useState(0);
  const [empSatisfaction, setEmpSatisfaction] = useState(0);
  const [productivity, setProductivity] = useState(0);

  const userData = {
    user_id: uid,
    game_id: gameId,
  };

  useEffect(() => {
    fetch(`https://www.simulator.ge/all_users`, {
      method: "Get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        let tmp = [];
        data.forEach((user) => {
          if (user[7] == gameId) {
            tmp.push(user);
          }
          setGroupMembers(tmp);
        });
      });
  }, []);

  useEffect(() => {
    fetch(`https://www.simulator.ge/emp_satisfaction`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(userData),
    })
      .then((response) => response.json())
      .then((data) => {
        setEmpSatisfaction(data);
      });
  }, []);
  useEffect(() => {
    fetch(`https://www.simulator.ge/productivity`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(userData),
    })
      .then((response) => response.json())
      .then((data) => {
        setProductivity(data);
      });
  }, []);
  useEffect(() => {
    fetch(`https://www.simulator.ge/customer_satisfaction`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(userData),
    })
      .then((response) => response.json())
      .then((data) => {
        setCustomerSatisfcation(data);
      });
  }, []);
  const labels = [
    "თანამშრომელთა ქულები",
    "ფასების ქულები",
    "ხარისხის ქულები",
    "საბონუსე ქულები",
    "სახელფასო ქულები",
    "ტრეინინგის ქულები",
    "მარკეტინგის ქულები",
  ];
  const user = localStorage.getItem("user");
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    if (
      customerSatisfaction.length > 0 &&
      empSatisfaction.length > 0 &&
      productivity.length > 0
    ) {
      setChartData([
        { "თანამშრომელთა ქულები": customerSatisfaction[0].emps_points },
        { "ფასების ქულები ": customerSatisfaction[0].price_points },
        { "ხარისხის ქულები": customerSatisfaction[0].quality_points },
        { "საბონუსე ქულები": empSatisfaction[0].bonus_points },
        { "სახელფასო ქულები": empSatisfaction[0].salary_points },
        { "ტრეინინგის ქულები ": productivity[0].training_points },
        { "მარკეტინგის ქულები": productivity[0].equipment_points },
      ]);
    }
  }, [customerSatisfaction, empSatisfaction, productivity]);

  const data = {
    labels,
    datasets: [
      {
        label: user,
        data: chartData.map((point) => Object.values(point)),
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
    ],
  };

  return (
    <div className="myPoints">
      {customerSatisfaction && empSatisfaction && productivity && (
        <div>
          <ChartJsApp data={data} />
        </div>
      )}
    </div>
  );
};

export default MyPoints;
