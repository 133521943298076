import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Landing from "./components/Landing/Landing";
import Header from "./components/Header/Header";
import Login from "./components/Login/Login";
import useToken from "../../src/HOC/useToken";
import Profile from "../Profile"
import { ToastContainer, toast } from 'react-toastify';
import "./input.css";
const Index = (props) => {
  const { token, removeToken, setToken } = useToken();
  const [user, setUser] = useState("");
  const username = JSON.parse(localStorage.getItem("user"));
  const [lang,setLang] = useState('en')
  useEffect(() => {
    if (username) {
      setUser(username);
    }
  }, [username]);
  const styles = {
    maxHeight:'90vh',
    color:'black',
  };
  return (
    <BrowserRouter>
    <ToastContainer />
      <Header user={user} lang={lang} setLang={setLang}/>
      <div className="layout relative" style={styles}>
        <Routes>
          <Route index element={<Landing lang={lang}/>} />
          <Route path="/login" element={<Login setToken={setToken} lang={lang}/>} />
          {token ? (
            <Route
              exact
              path="/profile/*"
              element={<Profile token={token} setToken={setToken} lang={lang}/>}
            ></Route>
          ) : (
            <Route
              exact
              path="/profile/*"
              element={<Login setToken={setToken} lang={lang}/>}
            ></Route>
          )}
        </Routes>
      </div>
    </BrowserRouter>
  );
};
export default Index;
