import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "./tools.css";
export const Gametask = (props) => {
  return (
    <div className="task">
      <div className="task-description">{props.desc}</div>
      <div className="task-header"> {props.header} </div>
      <div className="task-body">
        <ul>
          <li className="btn btn-info" id={props.ans1}>
            {props.ans1}
          </li>
          <li className="btn btn-info" id={props.ans2}>
            {props.ans2}
          </li>
          <li className="btn btn-info" id={props.ans3}>
            {props.ans3}
          </li>
          <li className="btn btn-info" id={props.ans4}>
            {props.ans4}
          </li>
        </ul>
      </div>
    </div>
  );
};

export const ProductCard = (props) => {
  return (
    <div className="products-wrapper">
      <table className="table table-striped">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">პროდუქტი</th>
            <th scope="col">ხარისხი</th>
            <th scope="col">ერთ ფასი</th>
            <th scope="col">რაოდენობა</th>
            <th scope="col">ჯამი</th>
            <th scope="col">კალათაში დამატება</th>
          </tr>
        </thead>
        <tbody>
          {props.arr
            ? props.arr.map((item) => {
                <tr>
                  <th scope="row">1</th>
                  <td>რძე</td>
                  <td>3</td>
                  <td>1</td>
                  <td>
                    <input type="number"></input>
                  </td>
                  <td>3</td>
                  <td className="buy">ყიდვა</td>
                </tr>;
              })
            : null}
        </tbody>
      </table>
    </div>
  );
};
export const getBudget = (gameId,userName)=>{
  const token = localStorage.getItem("token");
  fetch("https://www.simulator.ge/budget", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      let lastAmount = 0;
      let budgetUid = 0;
      data.forEach((user) => {
        if (user[3] == gameId && user[1] == userName) {
          lastAmount = user[2];
          budgetUid = user[0];
        }
      });
    })
}
export const Product = (props) => {
  const [dataUid, setDataUid] = useState(0);
  const [price, setPrice] = useState(0);
  const [qtt, setQtt] = useState(0);
  const [stocks, setStocks] = useState([]);
  const [stocksToCheck,setStocksToCheck]=useState({})
  const token = localStorage.getItem("token");
  const uid = localStorage.getItem("uid");
  const gameId = localStorage.getItem("gameId");
  useEffect(() => {
    fetch(`https://www.simulator.ge/yidvis_baza`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        let tmp = data.filter((product) => product[1] == uid);
        setStocks(tmp);
      });
  }, []);
  useEffect(()=>{
    let obj = {

    }
    if(stocks.length>0){
      stocks.forEach(stock=>{
        obj[stock[3]]=parseInt(stock[6])
      })
    }
    setStocksToCheck(obj)
  },[stocks])
  const EditStocks = (stockToedit, gameId, uid, qtt) => {
    const updateValue = +stockToedit[6] - qtt;
    const db = {
      user_id: stockToedit[1],
      game_id: stockToedit[2],
      product_id: stockToedit[3],
      xarisxi: stockToedit[4],
      pasi: stockToedit[5],
      raodenoba: updateValue,
    };
    fetch(`https://www.simulator.ge/edit_yidvis_baza/${stockToedit[0]}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(db),
    })
      .then((response) => response.json())
      .then((data) => {
        toast(`ბაზას დააკლდა`);
      });
  };
  const createProduct = (name, price, qtt) => {
    const uid = localStorage.getItem("uid");
    const token = localStorage.getItem("token");
    const gameId = localStorage.getItem("gameId");
    const carrier = +localStorage.getItem("carrier");
    let quality = 0;
    if (carrier === 1) {
      quality = 3;
    } else if (carrier === 2) {
      quality = 2;
    } else if (carrier === 3) {
      quality = 1;
    }
    let dt = {
      user_id: uid,
      game_id: gameId,
      product_id: name,
      xarisxi: quality,
      pasi: price,
      raodenoba: qtt,
    };
    if (name) {
      fetch(`https://www.simulator.ge/add_gayidvebis_baza`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(dt),
      })
        .then((response) => response.json())
        .then((data) => {
          toast(`დაემატა ${qtt} ცალი ${name}`);
          // window.location.reload(false)
        });
    }
  };

  const savePrice = (e) => {
    setPrice(e.target.value);
  };
  const saveQtt = (e) => {
    setQtt(e.target.value);
  };
  const addProduct = () => {
    let count = 0
    props.desc.forEach(ingr=>{
      if(stocksToCheck[ingr.ingredientId]>=parseInt(qtt)*ingr.ingredientQuantity){
        count ++
      }
    })
    if (count >= props.desc.length) {
      createProduct(props.id, price, qtt);

      props.desc.forEach((ingredient) => {

        stocks.forEach((stock) => {
    
          if (ingredient.ingredientId == +stock[3]) {
            let quantity = ingredient.ingredientQuantity * parseInt(qtt);
 
            EditStocks(stock, gameId, uid, quantity);
          }
        });
      });
      // EditStocks()
      // let userName = localStorage.getItem("user").slice(1, -1);
      // let currentPrice = +price;
      // let prodQtt = +qtt;
      // let coeficient = 1;
      // let avrg = props.avgPrice;
      // let difference = avrg - currentPrice;
      // let prodQuality = localStorage.getItem("carrier");
      // let qualityCoeficient = 1;
      // if (prodQuality === 1) {
      //   qualityCoeficient = 1;
      // } else if (prodQuality === 2) {
      //   qualityCoeficient = 0.9;
      // } else if (prodQuality === 3) {
      //   qualityCoeficient = 0.8;
      // }
      // if (difference === 0) {
      //   coeficient = 1;
      // } else if (difference <= -1) {
      //   coeficient = 0.8;
      //   currentPrice = avrg;
      // } else if (difference > 0) {
      //   coeficient = 1;
      // }
      // let sales = currentPrice * prodQtt * coeficient * qualityCoeficient;
      // updateBudget(gameId, userName, sales);
    } else if (count< props.desc.length) {
      alert("თქვენ არ გაქვთ საკმარისი მარაგები");
    }
  };
  return (
    <div className="product-card">
      <div className="product-name full-width">
        <h4>{props.name}</h4>
      </div>
      <div className="product-img">
        <img src={props.src} />
      </div>
      <div className="product-desc">
        <h5>შემადგენლობა:</h5>

        <ul className="product_description">
          {Array.isArray(props.desc) &&
            props.desc.map((item) => (
              <li key={item.ingredientNameEn}>
                {item.ingredientNameGe} {item.ingredientQuantity}{" "}
                {item.ingredientAmount}{" "}
              </li>
            ))}
        </ul>
      </div>
      <div className="product-qtt">
        <input
          type="number"
          placeholder="ფასი "
          onBlur={savePrice}
          className="input_nice"
        ></input>
        <label> ფასი</label>
        <br />
        <input
          type="number"
          placeholder="რაოდენობა"
          onBlur={saveQtt}
          className="input_nice"
        ></input>
        <label> რაოდენობა</label>
        <button
          className="btn btn-success"
          onClick={() => addProduct(props.id)}
        >
          მენიუში დამატება
        </button>
      </div>
    </div>
  );
};

export const changeLevel = (lvl) => {
  const uid = localStorage.getItem("uid");
  const token = localStorage.getItem("token");
  const gameId = localStorage.getItem("gameId");
  const groupNum = localStorage.getItem("carrier");

  fetch(`https://www.simulator.ge/user/${uid}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      let dt = {
        uid: data[0][0],
        uname: data[0][1],
        password: data[0][2],
        phone: data[0][3],
        email: data[0][4],
        lvl,
        game_id: gameId,
        status: "student",
        group_num: groupNum,
      };
      fetch(`https://www.simulator.ge/edit_user/${uid}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(dt),
      })
        .then((response) => response.json())
        .then((data) => {
          window.location.reload(false);
        });
    });
};

export const MyProduct = (props) => {
  return (
    <div className="my_prod_wrapper" key={props.name}>
      <div className="my_prod_header">
        <table className="table">
          <thead>
            <tr>
              <th>#</th>
              <th>დასახელება</th>
              <th>რაოდენობა</th>
              <th>ფასი</th>
            </tr>
          </thead>
          <tbody>
            {props.arr &&
              props.arr.map((product, i) => (
                <tr key={product[0]}>
                  <td>{i + 1}</td>
                  <td>{product[3]}</td>
                  <td>{product[6]}</td>
                  <td>{product[5]}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export const GetEmpName = (id) => {
  const token = localStorage.getItem("token");
  const [empName, setEmpName] = useState("");
  useEffect(() => {
    fetch("https://www.simulator.ge/emps", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        let tmp = "";
        data.forEach((element) => {
          if (id == element[0]) {
            tmp = element[3] + " " + element[4];
          }
        });
        setEmpName(tmp);
      });
  }, []);
  return empName;
};

export const updateBudget = (gameId, userName, change) => {
  const token = localStorage.getItem("token");
  const uid = localStorage.getItem("uid");
  fetch("https://www.simulator.ge/budget", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      let lastAmount = 0;
      let budgetUid = 0;
      data.forEach((user) => {
        if (user[3] == gameId && user[1] == userName) {
          lastAmount = user[2];
          budgetUid = user[0];
        }
      });
      let userData = {
        user_id: userName,
        game_id: +gameId,
        budget: +lastAmount + change,
      };
      fetch(`https://www.simulator.ge/edit_budget/${budgetUid}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(userData),
      })
        .then((response) => response.json())
        .then((data) => {});
    });
};
