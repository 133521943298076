import React, { useState, useEffect } from "react";
import ChartJsApp from "./chart/Chart";
const Ratings = (props) => {
  const [fullDbGameActivity, setFullDbGameActivity] = useState({});
  const [fullDbProducts, setFullDbProducts] = useState({});
  const [fullDbMarketActivity, setFullDbMarketActivity] = useState({});
  const [fullDbSales, setFullDbSales] = useState({});
  const [groupUsers, setGroupUsers] = useState({});
  const [groupBudgets, setGroupBudgets] = useState({});
  const [showMyPoints, setShowMyPoints] = useState(true);
  const [showGroupPoints, setShowGroupPoints] = useState(false);
  const [myDb,setMyDb] = useState([])
  const token = props.token;
  const gameId = props.gameId;
  const uid = props.uid;
  const level = props.level;
  const [datasets, setDatasets] = useState([]);
  const lang = props.lang
  useEffect(() => {
    fetch(`https://www.simulator.ge/all_users`, {
      method: "Get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        const filtered = data.filter((item) => item[7] == gameId);
        setGroupUsers(filtered);
      });
  }, []);
  useEffect(() => {
    fetch(`https://www.simulator.ge/game_activity`, {
      method: "Get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        const filtered = data.filter((item) => item[7] == gameId);
        setFullDbGameActivity(filtered);
      });
  }, []);
  useEffect(() => {
    fetch(`https://www.simulator.ge/gayidvebis_baza`, {
      method: "Get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setFullDbProducts(data.filter((item) => item[2] == gameId));
      });
  }, []);
  useEffect(() => {
    fetch(`https://www.simulator.ge/gayidvebi`, {
      method: "Get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setFullDbSales(data);
      });
  }, []);
  useEffect(() => {
    fetch(`https://www.simulator.ge/market_activity`, {
      method: "Get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        const filtered = data.filter((item) => item[2] == gameId);
        setFullDbMarketActivity(filtered);
      });
  }, []);
  const language = {
    en:{
      budegt:'Budget',
      points:"All Ponts",
      marketingPoints:"Marketing Points",
      empsPoints:"Employee Satisfaction",
      prodPoints:"Products Quality",
      round:"Round",
      my:"My Points",
      groups:"Group Points",
      empsQtt:"Employee Amount",
      empsPrice:"Employee Fees",
      empsMotivation:"Employee Motivation",
      inventarQtt:"Inventar Quantity",
      inventarPrice:"Inventar Price",
      inventarQuality:"Inventar Quality",
      marketingFees:"Marketing Budget",
      marketingQuality:"Marketing Quality",
      sales:"Sales"
    },
    ge:{
      budegt:'ბიუჯეტი',
      points:"საერთო ქულები",
      marketingPoints:"მარკეტინგის ხარისხი",
      empsPoints:"თანამშრომელთა კმაყოფილება",
      prodPoints:"პროდუქციის ხარისხი",
      round:"რაუნდი",
      my:"ჩემი ქულები",
      groups:"ჯგუფის ქულები",
      empsQtt:"თანამშრომელთა რაოდენობა",
      empsPrice:"ხელფასი",
      empsMotivation:"მოტივაცია",
      inventarQtt:"ინვენტარის რაოდენობა",
      inventarPrice:"ფასი",
      inventarQuality:"ხარისხი",
      marketingFees:"მარკეტინგული ხარჯები",
      marketingQuality:"მარკეტინგის ქულები",
      sales:"გაყიდვები"
    }
  }
  let chosen = language[lang]
  const labels = [
    chosen.budegt,
    chosen.points,
    chosen.marketingPoints,
    chosen.empsPoints,
    chosen.prodPoints
  ];
  const data = {
    labels,
    datasets,
  };
  useEffect(()=>{
    fetch(`https://www.simulator.ge/budget`, {
      method: "Get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        const filtered = data.filter(item => item[3]==gameId)
        const obj = []
        if(filtered.length>0){
          filtered.forEach((item,i)=>{
            obj[i] ={
              label:item[1],
              data:[item[2]/10000,getMpFull(item[1],item[3])+getGaFull(item[1],item[3],'emps')+getGaFull(item[1],item[3],'inv'),getMpFull(item[1],item[3]),getGaFull(item[1],item[3],'emps'),getGaFull(item[1],item[3],'inv')],
              backgroundColor:[
                "#65b545",
              ]
            }
            setDatasets((prevState) => obj);
          })
        }
        setGroupBudgets(filtered)
      })
  },[fullDbSales])
  useEffect(()=>{
    if (fullDbGameActivity.length > 0) {
      let myDb = [];
      const filtered = fullDbGameActivity.filter(
        (item) => item[1] == uid && item[7] == gameId
      );
      filtered.forEach((item) => {
        myDb.push({
          empsQtt: item[3],
          empsPrice: parseInt(item[3]) * 1200,
          empsQuality: item[4],
          inventarQtt: item[5],
          inventarPrice: parseInt(item[5]) * 720,
          inventarQuality: item[6],
          myProductsPrice: "x",
          mySales: "x",
          budget: "x",
          level: item[2],
        })
      });
      setMyDb(myDb)
    }
  },[fullDbGameActivity,fullDbSales])
  const getSales = (level,uid,gameId)=>{
    let sales = 0
    if(fullDbSales.length>0){

      fullDbSales.forEach(item =>{
        if(item[1]==gameId && item[2]==uid &&item[4]==level){
          sales=parseInt(item[3])
        }
      })
    }
    return sales
  }
  const getMp = (level,uid,gameId,req) =>{
    
    let mp = 0
    let mx = 0
    if(fullDbMarketActivity.length>0){
      fullDbMarketActivity.forEach(item =>{
        if(item[1]==uid && item[2]==gameId &&item[5]==level){
          mp=parseInt(item[3])
          mx=parseInt(item[4])
        }
      })
      if(req==='price'){
        return mp
      }else if(req==='xarisxi'){
        return mx
      }
    }
  }
  const getMpFull = (uid,gameId)=>{
    let myPoints= 0
      if(fullDbGameActivity.length>0){
        const filtered = fullDbMarketActivity.filter(item => item[2]==gameId && item[1]==uid ) 
        filtered.forEach(item => {
          myPoints += parseInt(item[4])
        })
      }
    return myPoints
  }
  const getGaFull = (uid,gameId,mode)=>{
    let emps=0
    let inv =0
    if(fullDbGameActivity.length>0){
      const filtered = fullDbGameActivity.filter(item => item[1]==uid && item[7]==gameId)
      filtered.forEach(item => {
        emps += parseInt(item[4])
        inv += parseInt(item[6])
      })
    }
    
    if(mode==='emps'){
      return emps
    }else if (mode==='inv'){
      return inv
    }
  }
  const handleClick = () => {
    setShowGroupPoints(!showGroupPoints);
    setShowMyPoints(!showMyPoints);
  };

  const MyStats = () => {
    return (
      <div className="mystats-header w-full flex flex-col items-center p-2 m-2 rounded-xl drop-shadow-xl" style={{
        maxHeight:'80vh'
      }}>
        <div className="bg-readableBg flex flex-col-reverse">
          {myDb.length>0 && myDb.map(item =>(
            <div className="text-center p-2"> 
              <h2>{chosen.round} : {item.level}</h2>
          <table className="table-auto border p-2 m-2">
              <thead>
                <tr className="border rounded-md p-2 text-center">
                  <th className="p-2 border text-center">
                  {chosen.empsQtt} 
                  </th>
                  <th className="p-2 border">{chosen.empsPrice} </th>
                  <th className="p-2 border">{chosen.empsMotivation}</th>
                </tr>
              </thead>
              <tbody>
                <tr className="border">
                  <td className="p-2 border text-center">{item.empsQtt}</td>
                  <td className="p-2 border text-center">{item.empsPrice}</td>
                  <td className="p-2 border text-center">{item.empsQuality}</td>
                </tr>
              </tbody>
              <tr className="border">
                <th className="p-2 border">{chosen.inventarQtt}</th>
                <th className="p-2 border">{chosen.inventarPrice}</th>
                <th className="p-2 border">{chosen.inventarQuality}</th>
              </tr>
              <tr>
                <td className="p-2 border text-center">{item.inventarQtt}</td>
                <td className="p-2 border text-center">{item.inventarPrice}</td>
                <td className="p-2 border text-center">{item.inventarQuality}</td>
              </tr>
              <tr>
                <th className="p-2 border">{chosen.marketingFees}</th>
                <th className="p-2 border">{chosen.marketingQuality}</th>
                <th className="p-2 border">{chosen.sales}</th>
              </tr>
              <tr>
                <td className="p-2 border text-center">{getMp(item.level,uid,gameId,'price')}</td>
                <td className="p-2 border text-center">{getMp(item.level,uid,gameId,'xarisxi')}</td>
                <td className="p-2 border text-center">{getSales(item.level,uid,gameId)}</td>
              </tr>
          </table>
          </div>
          ))}
    
        </div>
      </div>
    );
  };
  const GroupStats = ()=>{
      return (
        <div className="bg-readableBg p-2 m-2 rounded-xl drop-shadow-xl" style={{
          width:'70vw'
        }}>
          <ChartJsApp data={data}/>
   
          </div>
      )
  
  }
  return (
    <div className="ranking-wrapper " >
      <div className="ranking-header flex justify-center">
        <button
          className="bg-buttonColor p-2 rounded-md drop-shadow-xl mr-2"
          onClick={handleClick}
        >
          {chosen.my}
        </button>
        <button
          className="bg-buttonColor p-2 rounded-md drop-shadow-xl mr-2"
          onClick={handleClick}
        >
          {chosen.groups}
        </button>
      </div>
      {showMyPoints && <MyStats />}
      {showGroupPoints && <><GroupStats/></>}
    </div>
  );
};
export default Ratings;
