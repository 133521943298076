import { useState, useEffect } from "react";
import CancelIcon from '@mui/icons-material/Cancel';
import {toast} from 'react-toastify'
const Users = () => {
  const [groupName, setGroupName] = useState("");
  const [selectedGroup, setSelectedGroup] = useState({});
  const groups = [
    {
      name: "ჯგუფი N 1",
      level: "1",
      task: "1",
      score: "0",
      rank: "0",
      members: ["givi", "kote", "maka", "lela", "miriani"],
    },
    {
      name: "ჯგუფი N 2",
      level: "6",
      task: "5",
      score: "40",
      rank: "7",
    },
    {
      name: "ჯგუფი N 3",
      level: "1",
      task: "1",
      score: "0",
      rank: "0",
    },
    {
      name: "ჯგუფი N 4",
      level: "11",
      task: "2",
      score: "5",
      rank: "100",
    },
    {
      name: "ჯგუფი N 5",
      level: "10",
      task: "3",
      score: "5",
      rank: "3",
    },
    {
      name: "ჯგუფი N 6",
      level: "4",
      task: "5",
      score: "10",
      rank: "1",
    },
  ];
  const handleClick = (e) => {

    setGroupName(e.target.value);
  };
  useEffect(() => {
    groups.forEach((item) => {
      if (item.name === groupName) {
        setSelectedGroup(item);
      }
    });
  }, [groupName]);
  const [selectedUser, setSelectedUser] = useState('')
  const showInfo = (event)=>{
    const modal = document.getElementById("user-modal")
    modal.style.display='flex'

      setSelectedUser(event.target.value)
    }
    const closeModal=()=>{
        const modal = document.getElementById("user-modal")
        modal.style.display='none'

  }
  const sendMessage = ()=>{
    toast('შეტყობინება გაგზავნილია')
  }
  return (
    <div className="groups-wrapper">
      <div className="group-names">
        <h2>იუზერები</h2>
        {groups.map((item) => (
          <button
            className="btn btn-success"
            key={item.name}
            onClick={handleClick}
            value={item.name}
          >
            {" "}
            {item.name}{" "}
          </button>
        ))}
        <button className="btn btn-success">სტატისტიკა</button>
      </div>
      <div className="group-info">
        <div className="group-info-header">
          {" "}
          სახელი :<span className="info-span"> {groupName} </span>
        </div>
        <div className="group-info-body">
          <ul>
            <li>
              მიმდინარე ლეველი :{" "}
              <span className="info-span">{selectedGroup.level}</span>
            </li>
            <li>
              მიმდინარე დავალება :{" "}
              <span className="info-span">{selectedGroup.task}</span>
            </li>
            <li>
              შედეგები :{" "}
              <span className="info-span">{selectedGroup.score}</span>
            </li>
            <li>
              რანკი : <span className="info-span">{selectedGroup.rank}</span>
            </li>
            <li></li>
          </ul>
        </div>
        <div className="group-info-footer">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">ჯგუფის წევრები</th>
                <th scope="col">მეილი</th>
                <th scope="col">წერილის გაგზავნა</th>
              </tr>
            </thead>
            <tbody>
              {selectedGroup.members &&
                selectedGroup.members.map((name, index) => (
                  <tr key={name}>
                    <th scope="row">{index + 1}</th>
                    <td>{name} </td>
                    <td>{name + "@gmail.com"}</td>
                    <td>
                      <button className="btn btn-info" onClick={showInfo} value={name}>info</button>
                      <input type="text"></input>{" "}
                      <button className="btn btn-success" onClick={sendMessage}>გაგზავნა</button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
      <div id="user-modal"> 
        
        <div className="modal-header"><CancelIcon color="secondary" onClick={closeModal} style={{cursor:'pointer'}}/></div>
        <div className="modal-body">იუზერი : {selectedUser}</div>
      
      </div>
    </div>
  );
};

export default Users;
