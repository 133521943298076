import { Link } from 'react-router-dom';
import { Button } from '../newGame/tools/tools';
const Landing = (props) => {
  let lang = props.lang
  const language = {
    en:{
      h1:'Simulation Instructions',
      p1:'The main goal of the simulator is to familiarize the participants with the real environment.',
      p2:'During the simulator, you are creating products, buying supplies, buying inventory.',
      p3:'Using the manager functionality, you train employees, develop service, inventory.',
      p4:'Define marketing costs',
      p5:'Compete against real rivals',
      h2:'Wish you luck!',
      startBtn:'start the game'
    },
    ge:{
      h1:'სიმულატორის ინსტრუქციები',
      p1:'სიმულატორის მთავარი მიზანია, მონაწილეები შეაჩვიონ რეალურ გარემოს.',
      p2:'სიმულატორის განმავლობაში, თქვენ ქმნით პროდუქტებს, იძენთ მარაგებს, იძენთ ინვენტარს.',
      p3:'მენეჯერის ფუნქციონალის გამოყენებით, ატრენინგებთ თანამშრომლებს, ავითარებთ სერვისს, ინვენტარს.',
      p4:'მენეჯერის ფუნქციონალის გამოყენებით, განსაზღვრავთ მარკეტინგულ ხარჯებს',
      p5:'ეჯიბრებით რალურ კონკურენტებს',
      h2:'გისურვებთ წარმატებებს!',
      startBtn:'თამაშის დაწყება'
    }
  }
  return (
    <div className="w-full flex flex-col bg-readableBg rounded-xl p-6">
      <div className="text-sm max-h-5/6 flex flex-col text-center items-center">
        <h2> {language[lang].h1} </h2>
        <p className='text-xl m-2'>{language[lang].p1}</p>
        <p className='text-xl m-2'>{language[lang].p2}</p>
        <p className='text-xl m-2'>{language[lang].p3}</p>
        <p className='text-xl m-2'>{language[lang].p4}</p>
        <p className='text-xl m-2'>{language[lang].p5}</p>
        <h3 style={{margin:'20px',}}> {language[lang].h2}</h3>

       
      <Link to='gameflow'>

        <button className='bg-acceptGreen p-2 m-2 rounded-xl drop-shadow-xl'>{language[lang].startBtn}</button>
      </Link>
      </div>
    
    </div>
  );
};

export default Landing;
