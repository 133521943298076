import React from "react";
import './spinner.css'
import loadingBg from '../resources/backgrounds/loadingBg.jpg'
const LoadingSpinner = ()=>{
    return (
        <div className="spinner-container"       style={{
          backgroundImage: `url(${loadingBg})`,
        }}>
        <div className="loading-spinner">
        </div>
      </div>
    )
}
export default LoadingSpinner