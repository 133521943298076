import "./admin.css";
import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { toast } from "react-toastify";
import { UpdateBudget } from "../../game/tools/tools";
const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));
const AddUser = () => {
  const [expanded, setExpanded] = React.useState("panel1");
  const [leagues, setLeagues] = useState([]);
  let token = localStorage.getItem("token");
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [groupId, setGroupId] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [lastUser, setLastUser] = useState([]);

  const saveGroupId = (e) => {
    leagues.forEach((league) => {
      if (league[5] == e.target.value) {
        setGroupId(league[0]);
      }
    });
    localStorage.setItem("groupNum", e.target.value);
  };
  const saveUserName = (e) => {
    setUserName(e.target.value);
  };
  const saveUserEmail = (e) => {
    setEmail(e.target.value);
  };
  const saveUserPhone = (e) => {
    setPhone(e.target.value);
  };
  const saveUserPassword = (e) => {
    setPassword(e.target.value);
  };
  const handleSave = () => {
    let token = localStorage.getItem("token");
    let userData = JSON.stringify({
      email: email,
      password: password,
      uname: userName,
      phone: phone,
      group_num: 0,
      status: "student",
      lvl: "1",
      game_id: groupId,
    });

    fetch("https://www.simulator.ge/adduser", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: userData,
    })
      .then((response) => response.json())
      .then((data) => {
              fetch("https://www.simulator.ge/all_users", {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                },
              })
                .then((response) => response.json())
                .then((data) => {
                  let userId=data[data.length-1][0]
                  let userData = {
                    user_id: userId,
                    game_id: groupId,
                    budget: "50000",
                  };
                  fetch("https://www.simulator.ge/add_budget", {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify([userData]),
                  })
                    .then((response) => response.json())
                    .then((data) => {
                      toast('იუზერი დაემატა წარმატებით')
                    })
                });
        
      });
    // localStorage.setItem("user", JSON.stringify(userName));
    // toast('ადმინის პანელი')
  };
  const [allUsers, setAllUsers] = useState([]);
  useEffect(() => {
    fetch("https://www.simulator.ge/all_users", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setAllUsers(data);
      });
  }, [lastUser]);
  useEffect(() => {
    setLastUser(allUsers[allUsers.length - 1]);
  }, [allUsers]);
  const handleDelete = (id) => {
    fetch(`https://www.simulator.ge/delete_user/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        toast(data.msg);
        fetch("https://www.simulator.ge/all_users", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
          .then((response) => response.json())
          .then((data) => setAllUsers(data));
      });
  };
  useEffect(() => {
    fetch("https://www.simulator.ge/leagues", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setLeagues(data);
      });
  }, []);
  return (
    <div className="addgroup">
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography>იუზერების დამატება</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div>
            <div className="form-wrapper">
              <form>
                <div className="form-group">
                  <label htmlFor="groupname">ჯგუფის ID</label>
                  <select
                    type="text"
                    className="form-control"
                    id="groupname"
                    aria-describedby="groupname"
                    placeholder="ჯგუფის სახელი"
                    onChange={saveGroupId}
                  >
                    <option>აირჩიეთ ლიგა</option>
                    {leagues &&
                      leagues.map((name) => (
                        <option key={name}>{name[5]}</option>
                      ))}
                  </select>
                </div>
                <div className="form-group">
                  <label htmlFor="name">მონაწილეს სახელი</label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    placeholder="სახელი და გვარი"
                    onChange={saveUserName}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="useramount">მონაწილეს იმეილი</label>
                  <input
                    type="email"
                    className="form-control"
                    id="useramount"
                    placeholder="იმეილი"
                    onChange={saveUserEmail}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="useramount">მონაწილეს ტელეფონი</label>
                  <input
                    type="phone"
                    className="form-control"
                    id="useramount"
                    placeholder="ტელეფონის ნომერი"
                    onChange={saveUserPhone}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="useramount">პაროლი</label>
                  <input
                    type="text"
                    className="form-control"
                    id="useramount"
                    placeholder="password"
                    onChange={saveUserPassword}
                  />
                </div>
              </form>
              <button
                type="submit"
                className="btn btn-primary"
                onClick={handleSave}
              >
                დამატება
              </button>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === "panel3"}
        onChange={handleChange("panel3")}
      >
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography>იუზერის წაშლა</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div>
            <div className="form-wrapper">
              <div className="form-group">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">ID</th>
                      <th scope="col">იუზერი</th>
                      <th scope="col">ტელეფონი</th>
                      <th scope="col">იმეილი</th>
                      <th scope="col">მიმდინარე ლეველი</th>
                      <th scope="col">სტატუსი</th>
                      <th scope="col">წაშლა</th>
                    </tr>
                  </thead>
                  <tbody>
                    {allUsers.length > 1
                      ? allUsers.map((item) => (
                          <tr key={item[0]} value={item[0]}>
                            <th scope="row">{item[0]}</th>
                            <td>{item[1]}</td>
                            <td>{item[3]}</td>
                            <td>{item[4]}</td>
                            <td>{item[5]}</td>
                            <td>{item[8]}</td>
                            <td>
                              <button onClick={() => handleDelete(item[0])}>
                                წაშლა
                              </button>
                            </td>
                          </tr>
                        ))
                      : null}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
export default AddUser;
