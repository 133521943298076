import React, { useState, useEffect } from "react";
import Task2 from "./level1/Task2";
const Hr = () => {
  const token = localStorage.getItem("token");
  const uid = localStorage.getItem("uid");
  const [level, setLevel] = useState("0");

  useEffect(() => {
    fetch(`https://www.simulator.ge/all_users`, {
      method: "Get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        data.forEach((item) => {
          if (item[0] == uid) {
            setLevel(item[5]);
          }
        });
      });
  }, []);
  return <Task2 level={level} />;
};
export default Hr;
