import { data } from "autoprefixer";
import { DatasetController } from "chart.js";
import React, { useState, useEffect } from "react";
import ChartJsApp from "./chart/Chart";
// import { Chart } from './chart';

async function getEmpSatisfaction(uid, gameId, token) {
  const userData = {
    user_id: uid,
    game_id: gameId,
  };
  const response = await fetch(`https://www.simulator.ge/emp_satisfaction`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(userData),
  }).then((res) => res.json());
  return response;
}

async function getProductivity(uid, gameId, token) {
  const userData = {
    user_id: uid,
    game_id: gameId,
  };
  const response = await fetch(`https://www.simulator.ge/productivity`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(userData),
  }).then((res) => res.json());
  return response;
}

async function getCustomerSatisfaction(uid, gameId, token) {
  const userData = {
    user_id: uid,
    game_id: gameId,
  };
  const response = await fetch(
    `https://www.simulator.ge/customer_satisfaction`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(userData),
    }
  ).then((res) => res.json());
  return response;
}

const useChartData = (groupMembers) => {
  const token = localStorage.getItem("token");
  const [empSat, setEmpSat] = useState({});
  const [prodvi, setProdvi] = useState({});
  const [custSat, setCustSat] = useState({});

  useEffect(() => {
    groupMembers.forEach((member) => {
      getEmpSatisfaction(member[0], member[7], token).then((data) => {
        setEmpSat((prevState) => ({
          ...prevState,
          [member[1]]: data,
        }));
      });
      getCustomerSatisfaction(member[0], member[7], token).then((data) => {
        setCustSat((prevState) => ({
          ...prevState,
          [member[1]]: data,
        }));
      });
      getProductivity(member[0], member[7], token).then((data) => {
        setProdvi((prevState) => ({
          ...prevState,
          [member[1]]: data,
        }));
      });
    });
  }, [groupMembers]);

  return { empSat, prodvi, custSat };
};

const useGroupMembers = () => {
  const token = localStorage.getItem("token");
  const gameId = localStorage.getItem("gameId");
  const [groupMembers, setGroupMembers] = useState([]);
  useEffect(() => {
    fetch(`https://www.simulator.ge/all_users`, {
      method: "Get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        const result = data.filter((user) => user[7] === gameId);
        setGroupMembers(result);
      });
  }, []);

  return { groupMembers };
};

const GroupPoints = () => {
  const { groupMembers } = useGroupMembers();
  const { empSat, prodvi, custSat } = useChartData(groupMembers);
  const [datasets, setDatasets] = useState([]);
  const labels = [
    "თანამშრომელთა ქულები",
    "ფასების ქულები",
    "ხარისხის ქულები",
    "საბონუსე ქულები",
    "სახელფასო ქულები",
    "ტრეინინგის ქულები",
    "მარკეტინგის ქულები",
  ];
  const data = {
    labels,
    datasets,
  };
  useEffect(() => {
    if (!empSat || !prodvi || !custSat || !groupMembers.length) return;
    groupMembers.forEach((member) => {
      if (
        Object.keys(empSat).length >= groupMembers.length &&
        Object.keys(custSat).length >= groupMembers.length &&
        Object.keys(prodvi).length >= groupMembers.length &&
        datasets.length < groupMembers.length
      ) {
        const emp_points = custSat[member[1]][0].emps_points || 0;
        const price_points = custSat[member[1]][0].price_points || 0;
        const quality_points = custSat[member[1]][0].quality_points || 0;
        const bonus_points = empSat[member[1]][0].bonus_points || 0;
        const salary_points = empSat[member[1]][0].salary_points || 0;
        const training_points = prodvi[member[1]][0].training_points || 0;
        const equipment_points = prodvi[member[1]][0].equipment_points || 0;
        setDatasets((prevState) => {
          if (datasets)
            return [
              ...prevState,
              {
                label: member[1],
                data: [
                  emp_points,
                  price_points,
                  quality_points,
                  bonus_points,
                  salary_points,
                  training_points,
                  equipment_points,
                ],
                backgroundColor: [
                  "#65b545",
                  "#4f9136",
                  "#3b851e",
                  "#2441ab",
                  "#1940cf",
                  "#082696",
                ],
              },
            ];
        });
      }
    });
  }, [prodvi, groupMembers]);
  return (
    <div className="myPoints">
      {groupMembers && <div>{datasets && <ChartJsApp data={data} />}</div>}
    </div>
  );
};

export default GroupPoints;
