import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import { toast } from "react-toastify";
export const SendMail = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_9jb2p74",
        "template_jhvjalg",
        form.current,
        "oEIO6PXrwZzN6jCq5"
      )
      .then(
        (result) => {

          toast("მეილი გაგზავნილია");
        },
        (error) => {
          toast(error.text);
        }
      );
    form.current.reset();
  };

  return (
    <div className="email-wrapper">
      <form ref={form} onSubmit={sendEmail}>
        <div className="field-wrapper">
          <label>To</label>
          <input type="email" name="email_to" />
        </div>
        <div className="field-wrapper">
          <label>CC</label>
          <input type="email" name="email_cc" />
        </div>
        <div className="field-wrapper">
          <label>Subject</label>
          <input name="subject" type="text" />
        </div>
        <div className="field-wrapper">
          <label>Message</label>
          <textarea name="message" />
        </div>
        <button type="submit" value="Send" className="btn btn-info">
          გაგზავნა
        </button>
      </form>
    </div>
  );
};
