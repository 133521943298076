import React, { useState, useEffect } from "react";

const AddProduct = () => {
  const token = localStorage.getItem("token");
  const [productName, setProductName] = useState("");
  const [productInfo, setProductInfo] = useState("");
  const saveName = (e) => {
    setProductName(e.target.value);
  };
  const saveInfo = (e) => {
    setProductInfo(e.target.value);
  };
  const handleClick = () => {
    const data = {
      prod_name: productName,
      prod_info: productInfo,
      prod_pasi: "1",
      prod_raod: "1",
    };
    fetch(`https://www.simulator.ge/add_gayidvebi`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {});
  };
  return (
    <div
      style={{
        minHeight: "80vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <input
        type="text"
        placeholder="პროდუქტის სახელი"
        onChange={saveName}
        className="input_nice"
      ></input>
      <input
        type="text"
        placeholder="პროდუქტის ინფო"
        onChange={saveInfo}
        className="input_nice"
      ></input>
      <button className="btn btn-success" onClick={handleClick}>
        დამატება
      </button>
    </div>
  );
};
export default AddProduct;
