import * as React from "react";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import "./admin.css";
import { Link, useNavigate } from "react-router-dom";
const Admin = (props) => {
  const [state, setState] = React.useState({
    top: false,
    left: true,
    bottom: false,
    right: false,
  });

  const navigate=useNavigate()
  const userName=localStorage.getItem('user')
  if(userName!='"adminmail"'){
    navigate('/profile/user')
  }
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {[
          ["ჯგუფების დამატება", "addgroup"],
          ["იუზერების დამატება", "adduser"],
          ["ადმინის დამატება", "addadmin"],
          ["მეილის გაგზავნა", "sendmail"],
          ["შეტყობინებები", "notifications"],
        ].map((text, index) => (
          <ListItem key={text} disablePadding>
            <Link to={text[1]}>
              <ListItemButton>
                <ListItemIcon>
                  {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                </ListItemIcon>
                <ListItemText primary={text[0]} />
              </ListItemButton>
            </Link>
          </ListItem>
        ))}
      </List>
      <Divider />
      {/* <List>
        {[["ჯგუფები",'groups'], ["იუზერები",'users'], ["სხვა",'rest']].map(
          (text, index) => (
            <ListItem key={text} disablePadding>
              <Link to={text[1]}>
              <ListItemButton>
                <ListItemIcon>
                  {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                </ListItemIcon>
                <ListItemText primary={text[0]} />
              </ListItemButton>
              </Link>
            </ListItem>
          )
        )}
      </List> */}
    </Box>
  );

  return (
    <div className="admin">
      <div>
        {["left"].map((anchor) => (
          <React.Fragment key={anchor}>
            <Button onClick={toggleDrawer(anchor, true)}>{"მენიუ"}</Button>
            <SwipeableDrawer
              anchor={anchor}
              open={state[anchor]}
              onClose={toggleDrawer(anchor, true)}
              onOpen={toggleDrawer(anchor, true)}
            >
              {list(anchor)}
            </SwipeableDrawer>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default Admin;
