import React, {useState,useEffect} from "react";

import 'react-toastify/dist/ReactToastify.css';
import Index from "./newGame/Index";
function App(props) {

  return (
    <>
    <Index />
    <p style={{opacity:0,position:'absolute'}}>My Token = {window.token}</p>
    </>
  );
}

export default App;
