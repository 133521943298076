import React,{useState,useEffect} from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
const Login = (props) => {
    const navigate = useNavigate();
    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const [currentUser, setCurrentUser] = useState({});
    const lang=props.lang
    const handleLogin = (e) => {
      let userData = JSON.stringify({
        email: userName,
        password: password,
      });
      fetch("https://www.simulator.ge/token", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: userData,
      })
        .then((response) => response.json())
        .then((data) => {
          setCurrentUser(data);
        });
    };
    useEffect(() => {
      if (Object.keys(currentUser).length > 0) {
        fetch(`https://www.simulator.ge/all_users`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${currentUser.access_token}`,
          },
        })
          .then((response) => response.json())
          .then((data) => {
            data.forEach((element) => {
              if (element[0] === currentUser.uid) {
                localStorage.setItem("gameId", element[7]);
                localStorage.setItem("carrier", element[6]);
              }
            });
          });
        if (currentUser.status === "main_admin") {
          props.setToken(currentUser.access_token);
          localStorage.setItem("user", JSON.stringify(userName));
          localStorage.setItem("uid", currentUser.uid);
          navigate(`/profile/admin`);
          toast("ადმინის პანელი");
        } else if (currentUser.status === "admin") {
          props.setToken(currentUser.access_token);
          localStorage.setItem("user", JSON.stringify(userName));
          localStorage.setItem("uid", currentUser.uid);
          toast("ადმინის პანელი");
          navigate(`/profile/admin`);
        } else if (currentUser.status === "student") {
          props.setToken(currentUser.access_token);
          localStorage.setItem("user", JSON.stringify(userName));
          localStorage.setItem("uid", currentUser.uid);
          toast(`კეთილი იყოს თქვენი მობრძანება ${userName}`);
          navigate(`/profile/user`);
        }
      }
    }, [currentUser]);
    const handleUser = (event) => {
      setUserName(event.target.value);
    };
    const handlePass = (event) => {
      setPassword(event.target.value);
    };
    const language ={
      en:{
        email:'Email',
        pass:"Password",
        enter:"Enter"
      },     
      ge:{
        email:'მეილი',
        pass:"პაროლი",
        enter:"შესვლა"
      }
    }
  return (
    <div className="w-full flex flex-col justify-center items-center pt-6 ">
      <div className="inputs-wrapper flex items-center justify-center">

      <label className="block mr-6">
        <span className="after:content-['*'] after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700">
          {language[lang].email}
        </span>
        <input onChange={handleUser}
          type="email"
          name="email"
          className="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
          placeholder="you@example.com"
        />
      </label>
      <label className="block mr-6">
        <span className="after:content-['*'] after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700">
        {language[lang].pass}
        </span>
        <input onChange={handlePass}
          type="password"
          name="password"
          className="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
          placeholder="Password"
        />
      </label>
      </div>
      <button onClick={handleLogin} className="p-2 text-white bg-acceptGreen rounded-xl text-lg mt-4 w-48 ">{language[lang].enter}</button>
    </div>
  );
};
export default Login;
