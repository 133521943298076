import React, { useState, useEffect } from "react";
import LoadingSpinner from "../components/LoadingSpinner";
const Employee = () => {
  let uid = localStorage.getItem("uid");
  let token = localStorage.getItem("token");
  const [emps, setEmps] = useState([]);
  const [activeEmps, setActiveEmps] = useState([]);
  const [show, setShow] = useState(true);
  useEffect(() => {
    fetch("https://www.simulator.ge/active_emps", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        let tmp = [];
        data.forEach((element) => {
          if (element[2] == uid) {
            tmp.push(+element[1]);
          }
        });
        setEmps(tmp);
      });
  }, []);
  useEffect(() => {
    fetch("https://www.simulator.ge/emps", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        let tmp = [];
        data.forEach((element) => {
          if (emps.includes(element[0])) {
            tmp.push(element);
          }
        });

        setActiveEmps(tmp);
        setTimeout(() => {
          setShow(false);
        }, "1000");
      });
  }, [emps]);

  return (
    <div className="employee_wrapper">
      {show && <LoadingSpinner />}
      {activeEmps
        ? activeEmps.map((item) => (
            <div className="employee-card" value={item.name} key={item[0]}>
              <div className="employee-info">
                <ul>
                  <li>პოზიცია: {item[1]}</li>
                  <li>
                    სახელი: {item[3]} {item[4]}
                  </li>
                  <li>ასაკი: {item[6]}</li>
                  <li>სქესი:{item[5]}</li>
                  <li>გამოცდილება:{item[7]}</li>
                  <li>ხელფასი:{item[8]} </li>
                </ul>
              </div>
            </div>
          ))
        : null}
    </div>
  );
};
export default Employee;
