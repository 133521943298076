import React, { useState, useEffect } from "react";
import { Link, Route, Routes } from "react-router-dom";
import Decisions from "./Decisions";
// import "./game.css";
import Landing from "./Landing";
import { handleLogOut } from "../components/tools";
import GameFlow from "./GameFlow";
import Employee from "./Employee";
import Maragebi from "./Maragebi";
import Productebi from "./Productebi";
import Marketing from "./Marketing";
import Hr from "./Hr";
import Rank from "./Rank";
import cafeBg from "../resources/backgrounds/cafeBg.jpg";
import Ratings from "./Ratings";
const Game = (props) => {
  const lang = props.lang
  const header = document.querySelector("header-wrapper");
  const token = localStorage.getItem("token");
  const uid = localStorage.getItem("uid");
  const gameId = localStorage.getItem('gameId')
  const [level, setLevel] = useState("0");
 
  useEffect(() => {
    fetch(`https://www.simulator.ge/all_users`, {
      method: "Get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        data.forEach((item) => {
          if (item[0] == uid) {
            setLevel(item[5]);
          }
        });
      });
  }, []);
  // header && (header.style.display = "none");
  const logOut = () => {
    const header = document.querySelector("nav");
    const devider = document.querySelector(".devider");
    header && (header.style.display = "flex");
    devider && (devider.style.display = "flex");
    localStorage.removeItem("token");
    localStorage.removeItem("uid");
    localStorage.removeItem("gameId");
    localStorage.removeItem("groupNum");
    window.location.reload(false);
    handleLogOut();
  };
  return (
    <div
      className="w-full flex bg-cover bg-center h-90vh"
      style={{
        backgroundImage: `url(${cafeBg})`,
      }}
    >
      <div className="p-6 text-white w-full justify-center" style={{
        maxHeight:'80vh',
        maxWidth:'100vw'
      }}>
        
        <Routes>
          <Route index element={<Landing lang={lang}/>} />
          <Route path="/gameflow" element={<GameFlow gameId={gameId} level={level} lang={lang}/>} />
          <Route path="/ratings" element={<Ratings gameId={gameId} uid={uid} token={token} lang={lang}/>} />
          <Route path="/employee" element={<Employee gameId={gameId} lang={lang}/>} />
          <Route path="/maragebi" element={<Maragebi gameId={gameId} lang={lang}/>} />
          <Route path="/productebi" element={<Productebi gameId={gameId} lang={lang}/>} />
          <Route path="/marketing" element={<Marketing gameId={gameId} lang={lang}/>} />
          <Route path="/rank/*" element={<Rank gameId={gameId} lang={lang}/>} />
          <Route path="/hr" element={<Hr gameId={gameId} lang={lang}/>} />
        </Routes>
      </div>
    </div>
  );
};

export default Game;
