import React , {useState,useEffect} from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
export const FormBootsrap =(props) =>{
  return (
    <Form>
      <Form.Group className="mb-3" controlId="formBasicEmail">
        
        <Form.Control type="text" placeholder="სახელი და გვარი" />

      </Form.Group>
      <Form.Group className="mb-3" controlId="formBasicEmail">
        
        <Form.Control type="email" placeholder="თქვენი იმეილი" />
      </Form.Group>
      <Form.Group className="mb-3" controlId="formBasicEmail">
        
        <Form.Control type="text" placeholder="სასწავლებლის დასახელება" />

      </Form.Group>
      <Form.Group className="mb-3" controlId="formBasicPassword">
       
        <Form.Control type="number" placeholder="სტუდენტების რაოდენობა" />
      </Form.Group>
      <Form.Group className="mb-3" controlId="formBasicCheckbox">
        <Form.Check type="checkbox" label="ვეთანხმები პირობებს" />
      </Form.Group>
      <Button variant="primary" type="submit">
        მიიღე შეთავაზება
      </Button>
    </Form>
  );
}

export const handleLogOut = (props) => {
  localStorage.removeItem("user");
  localStorage.removeItem("uid");
  localStorage.removeItem("gameId");
  localStorage.removeItem("groupNum");
  localStorage.removeItem("token");
  fetch("https://www.simulator.ge.com/logout", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      props.token();
    })
    .catch((error) => {
      if (error.response) {
        console.log(error.response);
        console.log(error.response.status);
        console.log(error.response.headers);
      }
    });
    window.location.reload(false)

};
