import { useNavigate } from "react-router-dom";
import React, { useState } from "react";
import cafeBg from "../../resources/backgrounds/cafeBg.jpg";
import LoadingSpinner from "../LoadingSpinner";

const User = (props) => {
  let lang = props.lang
  const language = {
    en:{
      p1:'Your League has just begun, Please join other players',
      startText:'Start Simulation'
    },
    ge:{
      p1:'თქვენი ლიგა დაწყებულია, გთხოვთ შეუერთდეთ მოთამაშეებს',
      startText:'დაწყება'
    }
  }
  const navigate = useNavigate();
  

  const header = document.querySelector('.header-wrapper')
  
  header && (header.style.display = "none")


  const startGame = () => {
    navigate("/profile/user/game");
  };
  return (
    <div
      className="flex w-full text-white p-6 flex-col items-center bg-center bg-cover justify-center"
      style={{
        height:'90vh',
        backgroundImage: `url(${cafeBg})`,
      }}
    >
      <div className="flex flex-col m-6 bg-darkBlue rounded-xl p-6" >
        <p>{language[lang].p1}</p>
      </div>

      <div className="user-body">
        <button className="bg-acceptGreen p-2 rounded-xl hover:bg-confirmGreen" onClick={startGame}>
        {language[lang].startText}
        </button>
      </div>
    </div>
  );
};
export default User;
