import React, { useState, useEffect } from "react";
import { Link, Route, Routes } from "react-router-dom";
import "./rank.css";
import background from "../resources/backgrounds/rank_bg.jpg";
import RankLanding from "./RankLanding";
import MyPoints from "./MyPoints";
import GroupPoints from "./GroupPoints";
import AllRank from "./AllRank";
const Rank = () => {
  return (
    <div
      className="rank_wrapper"
      style={{
        backgroundImage: `url(${background})`,
      }}
    >
      <div className="rank_navigation">
        <div>
          <ul>
            <Link to="mypoints">
              <li>ჩემი ქულები</li>
            </Link>
            <Link to="grouppoints">
              <li>ჯგუფის ქულები</li>
            </Link>
            <Link to="myrank">
              <li>რანკი</li>
            </Link>
          </ul>
        </div>
      </div>
      <div className="rank_body">
        <Routes>
          <Route index element={<RankLanding />} />
          <Route path="/mypoints" element={<MyPoints />} />
          <Route path="/grouppoints" element={<GroupPoints />} />
          <Route path="/myrank" element={<AllRank/>} />
        </Routes>
      </div>
    </div>
  );
};
export default Rank;
